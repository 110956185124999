import Vue from 'vue'
import Router from 'vue-router'
import store from '../store'

import { MutationTypes, ActionTypes } from '../store/application/types'

import StepOne from '../pages/steps/StepOne.vue'
import StepTwo from '../pages/steps/StepTwo.vue'
import StepThree from '../pages/steps/StepThree.vue'
import StepFour from '../pages/steps/StepFour.vue'

// import Confirmation from '../pages/confirmation/Confirmation.vue'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Frontpage',
      redirect: '/step1'
    },
    {
      path: '/step1',
      name: 'StepOne',
      component: StepOne
    },
    {
      path: '/step2',
      name: 'StepTwo',
      component: StepTwo
    },
    {
      path: '/step3',
      name: 'StepThree',
      component: StepThree
    },
    {
      path: '/step4',
      name: 'StepFour',
      component: StepFour
    }
  ]
})

router.beforeEach((to, from, next) => {
  // Check if postkom is set
  if (to.query.postkom !== undefined) {
    store.commit(`application/${MutationTypes.SET_IS_POST_AND_FINANCE_UNION_MEMBER}`, true)
  }
  // Check if referer is set
  if (to.query.referer !== undefined) {
    store.commit(`application/${MutationTypes.SET_REFERRER_IDENTIFIER}`, to.query.referer)
    store.commit(`application/${MutationTypes.SET_WAS_ENLISTED}`, true)

    store.dispatch(`application/${ActionTypes.FETCH_REFERRER}`)
  }
  switch (to.name) {
    case 'StepTwo':
      if (!store.getters['application/stepOneValid']) {
        next({ name: 'StepOne' })
      }
      break
    case 'StepThree':
      if (!store.getters['application/stepTwoValid']) {
        next({ name: 'StepTwo' })
      }
      break
    case 'StepFour':
      if (!store.getters['application/stepThreeValid']) {
        next({ name: 'StepThree' })
      }
      break
    default:
      break
  }
  next()
})

export default router
