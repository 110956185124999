
/* Decorators */
import { Component, Vue } from 'vue-property-decorator'
import { State, Mutation, Getter } from 'vuex-class'

/* Components */
import {
  FormRow,
  FormTextField,
  FormSelect,
  FormSelectOption,
  Icon,
  FormCheckbox
} from '@fagforbundet/components'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import County from '../County.vue'
import EmployerComponent from '../Employer.vue'
import PostkomEmployer from '../PostkomEmployer.vue'

/* Types */
import {
  ApplicationState,
  MutationTypes
} from '../../../../store/application/types'

/* Vendor */
import dayjs from 'dayjs'

@Component({
  components: {
    Icon,
    FormRow,
    FormSelect,
    FormSelectOption,
    FormTextField,
    FormCheckbox,
    ValidationProvider,
    ValidationObserver,
    County,
    EmployerComponent,
    PostkomEmployer
  }
})
export default class Apprentice extends Vue {
  @State('application') applicationState!: ApplicationState
  @Getter('isPostAndFinanceUnionMember', { namespace: 'application' })
  isPostAndFinanceUnionMember!: boolean

  @Getter('hasSelectedPostAndFinanceUnionCompany', { namespace: 'application' })
  hasSelectedPostAndFinanceUnionCompany!: boolean

  @Mutation(MutationTypes.SET_APPRENTICE_SUBJECT, { namespace: 'application' })
  setStudySubject!: (studySubject: string) => void

  @Mutation(MutationTypes.SET_APPRENTICE_STUDY_AREA, {
    namespace: 'application'
  })
  setStudyArea!: (setStudyArea: string) => void

  @Mutation(MutationTypes.SET_APPRENTICE_EMPLOYER, { namespace: 'application' })
  setEmployer!: (studyArea: string) => void

  @Mutation(MutationTypes.SET_EMPLOYEE_NUMBER, { namespace: 'application' })
  setEmployeeNumber!: (employeenumber: string | null) => void

  @Mutation(MutationTypes.SET_APPRENTICE_FINISHED_WHEN, {
    namespace: 'application'
  })
  setFinishedWhen!: (finishedWhen: string) => void

  @Mutation(MutationTypes.SET_APPRENTICE_INCLUDE_INSURANCE, {
    namespace: 'application'
  })
  setIncludeInsurance!: (includeInsurance: boolean) => void

  private mounted (): void {
    console.log('Apprentice::mounted()')
  }

  public $refs!: {
    observer: InstanceType<typeof ValidationObserver>;
    county: County;
    employer: EmployerComponent;
    postkomEmployer: PostkomEmployer;
  }

  get semesters (): string[] {
    const data: string[] = []

    let date = dayjs()
    for (let i = 0; i < 10; i++) {
      const semester =
        date.month() >= 6
          ? this.$t('global.fall').toString()
          : this.$t('global.spring').toString()
      const year = date.year()
      data.push(`${year} – ${semester}`)

      date = date.add(6, 'month')
    }

    return data
  }

  get employeenumber (): string | null {
    return this.applicationState.employeenumber
  }

  set employeenumber (employeenumber: string | null) {
    this.setEmployeeNumber(employeenumber)
  }

  get studySubject (): string {
    return this.applicationState.apprenticeSubject ?? ''
  }

  set studySubject (studySubject: string) {
    this.setStudySubject(studySubject)
  }

  get studyArea (): string {
    return this.applicationState.apprenticeStudyArea ?? ''
  }

  set studyArea (studyArea: string) {
    this.setStudyArea(studyArea)
  }

  get includeInsurance (): boolean {
    return this.applicationState.apprenticeIncludeInsurance
  }

  set includeInsurance (includeInsurance: boolean) {
    this.setIncludeInsurance(includeInsurance)
  }

  get finishedWhen (): string {
    return this.applicationState.apprenticeFinishedWhen ?? ''
  }

  set finishedWhen (finishedWhen: string) {
    this.setFinishedWhen(finishedWhen)
  }

  public async isValid () {
    const countyIsValid = await this.$refs.county.isValid()
    const isValid = await this.$refs.observer.validate()
    return isValid && countyIsValid
  }
}
