import axios, { AxiosPromise } from 'axios'
import { Globals } from '../Globals'
import { Organization } from '@/types'
import { Person } from '@/store/application/types'

export default {
  searchPerson (phone: string, ssn: string): AxiosPromise<{ person: Person }> {
    return axios({
      method: 'GET',
      baseURL: Globals.PROXY_API_URL,
      url: `/v1/search/person?phone=${phone}&ssn=${ssn}`
    })
  },
  searchOrganization (
    name: string
  ): AxiosPromise<{ organizations: Organization[] }> {
    return axios({
      method: 'GET',
      baseURL: Globals.PROXY_API_URL,
      url: `/v1/search/organization?name=${name}&subunit=false`
    })
  },
  checkIfOrganizationIsPostAndFinanceEmployer (
    organizationNumber: string
  ): AxiosPromise<{ isPostAndFinance: boolean }> {
    return axios({
      method: 'GET',
      baseURL: Globals.PROXY_API_URL,
      url: `/v1/external-organization/by-organization-number/${organizationNumber}/is-post-and-finance-employer`
    })
  }
}
