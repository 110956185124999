
/* Decorators */
import { Component, Vue } from 'vue-property-decorator'
import { State, Mutation } from 'vuex-class'

/* Components */
import { FormRow, FormColumn, FormTextField } from '@fagforbundet/components'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

/* Types */
import {
  ApplicationState,
  MutationTypes
} from '../../../store/application/types'

@Component({
  components: {
    FormRow,
    FormColumn,
    FormTextField,
    ValidationProvider,
    ValidationObserver
  }
})
export default class Name extends Vue {
  @State('application') applicationState!: ApplicationState
  @Mutation(MutationTypes.SET_FIRSTNAME, { namespace: 'application' })
  setFirstname!: (firstname: string) => void

  @Mutation(MutationTypes.SET_LASTNAME, { namespace: 'application' })
  setLastname!: (lastname: string) => void

  public $refs!: {
    observer: InstanceType<typeof ValidationObserver>;
  }

  get firstname (): string {
    return this.applicationState.firstName ?? ''
  }

  set firstname (firstname: string) {
    this.setFirstname(firstname)
  }

  get lastname (): string {
    return this.applicationState.lastName ?? ''
  }

  set lastname (lastname: string) {
    this.setLastname(lastname)
  }

  public async isValid () {
    const isValid = await this.$refs.observer.validate()
    return isValid
  }
}
