import { Module } from 'vuex'
import { getters } from './getters'
import { actions } from './actions'
import { mutations } from './mutations'
import { RootState } from '../types'
import { ContentState } from './types'

export const state: ContentState = {
  publicGrantToken: undefined,
  loUnions: undefined,
  loUnionsError: undefined,
  professions: undefined,
  professionsError: undefined,
  counties: undefined,
  countiesError: undefined,
  countries: undefined,
  countriesError: undefined
}

const namespaced = true
export const content: Module<ContentState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
