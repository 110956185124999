import { MutationTree } from 'vuex'
import { ContentState, LoUnion, Profession, OrganizationalUnit, Country } from './types'

export const mutations: MutationTree<ContentState> = {
  publicGrantTokenLoaded (state, payload: string) {
    state.publicGrantToken = payload
  },
  loUnionsLoaded (state, payload: LoUnion[]) {
    state.loUnionsError = false
    state.loUnions = payload
  },
  loUnionsError (state) {
    state.loUnionsError = true
    state.loUnions = undefined
  },
  professionsLoaded (state, payload: Profession[]) {
    state.professions = payload
    state.professionsError = false
  },
  professionsError (state) {
    state.professions = undefined
    state.professionsError = true
  },
  countiesLoaded (state, payload: OrganizationalUnit[]) {
    state.counties = payload
    state.countiesError = false
  },
  countiesError (state) {
    state.counties = undefined
    state.countiesError = true
  },
  countriesLoaded (state, payload: Country[]) {
    state.countries = payload
    state.countriesError = false
  },
  countriesError (state) {
    state.countries = undefined
    state.countriesError = true
  }
}
