
/* Decorators */
import { Component, Vue } from 'vue-property-decorator'
import { State, Mutation } from 'vuex-class'

/* Components */
import { FormRow, FormTextField } from '@fagforbundet/components'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

/* Types */
import {
  ApplicationState,
  MutationTypes
} from '../../../store/application/types'

@Component({
  components: {
    FormRow,
    FormTextField,
    ValidationProvider,
    ValidationObserver
  }
})
export default class Email extends Vue {
  @State('application') applicationState!: ApplicationState
  @Mutation(MutationTypes.SET_EMAIL, { namespace: 'application' }) setEmail!: (
    email: string
  ) => void

  public $refs!: {
    observer: InstanceType<typeof ValidationObserver>;
  }

  get email (): string {
    return this.applicationState.emailAddress ?? ''
  }

  set email (email: string) {
    this.setEmail(email)
  }

  public async isValid () {
    const isValid = await this.$refs.observer.validate()
    return isValid
  }
}
