
/* Decorators */
import { Component, Vue, Prop } from 'vue-property-decorator'
import { State, Mutation, Action } from 'vuex-class'

/* Components */
import { FormRow, FormSelect, FormSelectOption } from '@fagforbundet/components'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

/* Types */
import {
  ApplicationState,
  MutationTypes
} from '../../../store/application/types'

/* Globals */
import { ContentState } from '@/store/content/types'
import { OrganizationalUnit } from '@/store/content/types/OrganizationalUnit'

@Component({
  components: {
    FormRow,
    FormSelect,
    FormSelectOption,
    ValidationProvider,
    ValidationObserver
  }
})
export default class County extends Vue {
  @State('application') applicationState!: ApplicationState
  @State('content') contentState!: ContentState
  @Action('fetchCounties', { namespace: 'content' })
  fetchCounties!: () => Promise<void>

  @Mutation(MutationTypes.SET_COUNTY_UUID, { namespace: 'application' })
  setCounty!: (county: string) => void

  @Prop({ type: String, required: true }) label!: string

  private mounted (): void {
    console.log('County::mounted()')
    if (!this.contentState.counties) {
      this.fetchCounties()
    }
  }

  public $refs!: {
    observer: InstanceType<typeof ValidationObserver>;
  }

  get countiesList (): OrganizationalUnit[] {
    return this.contentState.counties ?? []
  }

  get countyUuid (): string {
    return this.applicationState.countyOrganizationalUnitUuid ?? ''
  }

  set countyUuid (countyUuid: string) {
    this.setCounty(countyUuid)
  }

  public async isValid () {
    const isValid = await this.$refs.observer.validate()
    return isValid
  }
}
