import { GetterTree } from 'vuex'
import { ContentState, LoUnion } from './types'
import { RootState } from '../types'
import { FormTypeaheadOption } from '../../types'

export const getters: GetterTree<ContentState, RootState> = {
  loUnions (state): FormTypeaheadOption[] {
    const { loUnions } = state

    if (!loUnions) {
      return []
    }

    return loUnions.map((union: LoUnion) => {
      return { id: union.uuid, value: union.name, label: union.name }
    })
  },
  professions (state): FormTypeaheadOption[] {
    const { professions } = state
    if (!professions) {
      return []
    }

    return professions.map((profession) => {
      return {
        id: profession.uuid,
        value: profession.uuid,
        label: profession.name
      }
    })
  },
  countries (state): FormTypeaheadOption[] {
    const { countries } = state
    if (!countries) {
      return []
    }
    return countries.map((country) => {
      return { id: country.code, label: country.name }
    })
  }
}
