
/* Decorators */
import { Component, Vue, Prop } from 'vue-property-decorator'
import { State, Mutation } from 'vuex-class'

/* Components */
import {
  FormRow,
  FormTypeaheadSelect,
  FormTextField
} from '@fagforbundet/components'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

/* Types */
import {
  ApplicationState,
  MutationTypes,
  Employer
} from '../../../store/application/types'
import { FormTypeaheadOption } from '../../../types'

/* Services */
import ProxyApiService from '../../../services/ProxyApiService'

@Component({
  components: {
    FormRow,
    FormTypeaheadSelect,
    ValidationProvider,
    ValidationObserver,
    FormTextField
  }
})
export default class EmployerComponent extends Vue {
  @State('application') applicationState!: ApplicationState
  @Prop({ type: String, required: true }) label!: string
  @Prop({ type: String, required: true }) otherLabel!: string
  @Prop({ type: String, required: true }) helptext!: string
  @Prop({ type: Boolean, required: true }) otherEmployer!: boolean
  @Mutation(MutationTypes.SET_EMPLOYER, { namespace: 'application' })
  setEmployer!: (employer: Employer | null) => void

  @Mutation(MutationTypes.SET_EMPLOYER_2, { namespace: 'application' })
  setEmployerTwo!: (employer: Employer | null) => void

  @Mutation(MutationTypes.SET_SELECTED_POST_AND_FINANCE_UNION_COMPANY, {
    namespace: 'application'
  })
  setSelectedPostkomCompany!: (selected: boolean) => void

  @Mutation(MutationTypes.SET_OTHER_EMPLOYER, { namespace: 'application' })
  setEmployerOtherEmployer!: (employer: Employer | null) => void

  @Mutation(MutationTypes.SET_OTHER_EMPLOYER_2, { namespace: 'application' })
  setEmployerOtherEmployerTwo!: (employer: Employer | null) => void

  private searchDebounce = 0
  public $refs!: {
    observer: InstanceType<typeof ValidationObserver>;
  }

  get employer (): Employer | null {
    if (this.otherEmployer) {
      return this.applicationState.employer2
    } else {
      return this.applicationState.employer
    }
  }

  set employer (employer: Employer | null) {
    if (this.otherEmployer) {
      this.setEmployerTwo(employer)
    } else {
      this.setEmployer(employer)
      this.checkIfOrganizationIsPostAndFinanceEmployer(
        employer?.organizationNumber ?? null
      )
    }
  }

  get employerOtherEmployer (): string {
    if (this.otherEmployer) {
      return this.applicationState.employer2OtherEmployer?.name ?? ''
    } else {
      return this.applicationState.employerOtherEmployer?.name ?? ''
    }
  }

  set employerOtherEmployer (employerOtherEmployer: string) {
    const otherEmployer = employerOtherEmployer
      ? { name: employerOtherEmployer }
      : null
    if (this.otherEmployer) {
      this.setEmployerOtherEmployerTwo(otherEmployer)
    } else {
      this.setEmployerOtherEmployer(otherEmployer)
    }
  }

  private checkIfOrganizationIsPostAndFinanceEmployer (
    organizationNumber: string | null
  ) {
    if (!organizationNumber) {
      this.setSelectedPostkomCompany(false)
      return
    }
    ProxyApiService.checkIfOrganizationIsPostAndFinanceEmployer(
      organizationNumber
    )
      .then((resp) => {
        if (resp.status === 200) {
          const isPostAndFinance: boolean =
            resp && resp.data && resp.data.isPostAndFinance
          this.setSelectedPostkomCompany(isPostAndFinance)
        }
      })

      .catch(() => {
        this.setSelectedPostkomCompany(false)
      })
  }

  employerLookup (term: string) {
    const results = Array<FormTypeaheadOption>()
    results.push({
      id: this.$t('employer.otheremployer').toString(),
      value: { name: this.$t('employer.otheremployer').toString() },
      label: this.$t('employer.otheremployer').toString()
    })
    return new Promise((resolve) => {
      clearTimeout(this.searchDebounce)
      this.searchDebounce = setTimeout(() => {
        if (term.length < 3) {
          resolve(results)
        } else {
          ProxyApiService.searchOrganization(term)
            .then((resp) => {
              if (resp.status === 200) {
                const organizations =
                  resp && resp.data && resp.data.organizations
                organizations.forEach((organization) => {
                  results.push({
                    id: organization.organizationNumber,
                    value: {
                      name: organization.organizationName,
                      organizationNumber: organization.organizationNumber
                    } as Employer,
                    label: organization.organizationName
                  })
                })
              }
              resolve(results)
            })
            .catch(() => {
              resolve(results)
            })
        }
      }, 600)
    })
  }

  public async isValid () {
    const isValid = await this.$refs.observer.validate()
    return isValid
  }
}
