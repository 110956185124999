
/* Decorators */
import { Component, Vue } from 'vue-property-decorator'

import { localeChanged, localize } from 'vee-validate'

@Component({
  components: {}
})
export default class Language extends Vue {
  public get isLanguageNorwegian (): boolean {
    return this.$root.$i18n.locale === 'no'
  }

  public setLanguage (language: string) {
    this.$root.$i18n.locale = language
    localize(language)
    localeChanged()
  }
}
