
/* Decorators */
import { Component, Vue } from 'vue-property-decorator'
import { State, Mutation, Getter, Action } from 'vuex-class'

/* Components */
import {
  Icon,
  FormRow,
  FormTextField,
  FormSelect,
  FormSelectOption,
  FormPhoneNumberField,
  FormTypeaheadSelect
} from '@fagforbundet/components'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import County from '../County.vue'
import EmployerComponent from '../Employer.vue'
import PostkomEmployer from '../PostkomEmployer.vue'

/* Types */
import {
  ApplicationState,
  MutationTypes
} from '../../../../store/application/types'
import { FormTypeaheadOption } from '../../../../types'
import { ContentState } from '@/store/content/types'

@Component({
  components: {
    FormRow,
    FormSelect,
    FormSelectOption,
    FormTextField,
    FormTypeaheadSelect,
    FormPhoneNumberField,
    ValidationProvider,
    ValidationObserver,
    EmployerComponent,
    PostkomEmployer,
    Icon,
    County
  }
})
export default class Employee extends Vue {
  @State('application') applicationState!: ApplicationState
  @State('content') contentState!: ContentState

  @Getter('isPostAndFinanceUnionMember', { namespace: 'application' })
  isPostAndFinanceUnionMember!: boolean

  @Getter('hasSelectedPostAndFinanceUnionCompany', { namespace: 'application' })
  hasSelectedPostAndFinanceUnionCompany!: boolean

  @Getter('professions', { namespace: 'content' })
  professions!: FormTypeaheadOption[]

  @Action('fetchProfessions', { namespace: 'content' })
  fetchProfessions!: () => Promise<void>

  @Mutation(MutationTypes.SET_HAS_TWO_EPLOYERS, { namespace: 'application' })
  setHasTwoEmployers!: (hasTwoEmployers: boolean) => void

  @Mutation(MutationTypes.SET_WORKPLACE, { namespace: 'application' })
  setWorkplace!: (workplace: string) => void

  @Mutation(MutationTypes.SET_EMPLOYEE_NUMBER, { namespace: 'application' })
  setEmployeeNumber!: (employeenumber: string | null) => void

  @Mutation(MutationTypes.SET_PROFESSION_UUID, { namespace: 'application' })
  setProfession!: (profession: string | null) => void

  @Mutation(MutationTypes.SET_POSITION, { namespace: 'application' })
  setPosition!: (altProfession: string) => void

  @Mutation(MutationTypes.SET_WORKPLACE_PERCENTAGE, {
    namespace: 'application'
  })
  setWorkplacePercentage!: (workplacePercentage: number) => void

  @Mutation(MutationTypes.SET_OTHER_PROFESSION, { namespace: 'application' })
  setOtherProfession!: (otherProfession: string) => void

  @Mutation(MutationTypes.SET_WORKPLACE_2, { namespace: 'application' })
  setWorkplace2!: (workplace2: string) => void

  @Mutation(MutationTypes.SET_PROFESSION_UUID_2, { namespace: 'application' })
  setProfession2!: (profession2: string | null) => void

  @Mutation(MutationTypes.SET_POSITION_2, { namespace: 'application' })
  setAltProfession2!: (altProfession2: string) => void

  @Mutation(MutationTypes.SET_WORKPLACE_PERCENTAGE_2, {
    namespace: 'application'
  })
  setWorkplacePercentage2!: (workplacePercentage2: number) => void

  @Mutation(MutationTypes.SET_OTHER_PROFESSION_2, { namespace: 'application' })
  setOtherProfession2!: (otherProfession2: string) => void

  public $refs!: {
    observer: InstanceType<typeof ValidationObserver>;
    county: County;
    employer: EmployerComponent;
    postkomEmployer: PostkomEmployer;
  }

  private mounted (): void {
    if (!this.contentState.professions) {
      this.fetchProfessions()
    }
  }

  get employeenumber (): string | null {
    return this.applicationState.employeenumber
  }

  set employeenumber (employeenumber: string | null) {
    this.setEmployeeNumber(employeenumber)
  }

  get workplace (): string {
    return this.applicationState.workplace ?? ''
  }

  set workplace (workplace: string) {
    this.setWorkplace(workplace)
  }

  get profession (): string | null {
    return this.applicationState.professionUuid
  }

  set profession (profession: string | null) {
    this.setProfession(profession)
  }

  get otherProfession (): string {
    return this.applicationState.otherProfession ?? ''
  }

  set otherProfession (otherProfession: string) {
    this.setOtherProfession(otherProfession)
  }

  get position (): string {
    return this.applicationState.position ?? ''
  }

  set position (position: string) {
    this.setPosition(position)
  }

  get workplacePercentage (): string {
    return String(this.applicationState.workplacePercentage ?? 0)
  }

  set workplacePercentage (workplacePercentage: string) {
    this.setWorkplacePercentage(+workplacePercentage)
  }

  get workplace2 (): string {
    return this.applicationState.workplace2 ?? ''
  }

  set workplace2 (workplace2: string) {
    this.setWorkplace2(workplace2)
  }

  get profession2 (): string | null {
    return this.applicationState.professionUuid2
  }

  set profession2 (profession2: string | null) {
    this.setProfession2(profession2)
  }

  get otherProfession2 (): string {
    return this.applicationState.otherProfession2 ?? ''
  }

  set otherProfession2 (otherProfession2: string) {
    this.setOtherProfession2(otherProfession2)
  }

  get altProfession2 (): string {
    return this.applicationState.position2 ?? ''
  }

  set altProfession2 (altProfession2: string) {
    this.setAltProfession2(altProfession2)
  }

  get workplacePercentage2 (): string {
    return String(this.applicationState.workplacePercentage2 ?? 0)
  }

  set workplacePercentage2 (workplacePercentage2: string) {
    this.setWorkplacePercentage2(+workplacePercentage2)
  }

  public async isValid () {
    const countyIsValid = await this.$refs.county.isValid()
    const employerIsValid = this.isPostAndFinanceUnionMember
      ? true
      : await this.$refs.employer.isValid()
    const postkomEmployerIsValid = this.isPostAndFinanceUnionMember
      ? await this.$refs.employer.isValid()
      : true
    const isValid = await this.$refs.observer.validate()
    return isValid && countyIsValid && employerIsValid && postkomEmployerIsValid
  }

  addEmployer (): void {
    this.setHasTwoEmployers(true)
  }

  removeEmployer (): void {
    this.setHasTwoEmployers(false)
    this.setWorkplacePercentage2(0)
    this.setAltProfession2('')
    this.setOtherProfession2('')
    this.setProfession2('')
  }

  get hasTwoEmployers (): boolean {
    return this.applicationState.hasTwoEmployers
  }
}
