
/* Decorators */
import { Component, Vue } from 'vue-property-decorator'
import { State, Mutation } from 'vuex-class'

/* Components */
import {
  Alert,
  FormButton,
  FormRow,
  FormColumn
} from '@fagforbundet/components'
import WorkStatusPicker from './components/WorkStatusPicker.vue'
import Student from './components/enlisteetypes/Student.vue'
import Employee from './components/enlisteetypes/Employee.vue'
import Pupil from './components/enlisteetypes/Pupil.vue'
import Apprentice from './components/enlisteetypes/Apprentice.vue'
import Progress from './components/Progress.vue'

/* Types */
import { ApplicationState, MutationTypes } from '../../store/application/types'

@Component({
  components: {
    Progress,
    WorkStatusPicker,
    Student,
    Employee,
    Pupil,
    Apprentice,
    Alert,
    FormButton,
    FormRow,
    FormColumn
  }
})
export default class StepTwo extends Vue {
  @State('application') applicationState!: ApplicationState
  @Mutation(MutationTypes.SET_CURRENT_STEP, { namespace: 'application' })
  setCurrentStep!: (currentStep: number) => void

  @Mutation(MutationTypes.SET_STEP_TWO_VALID, { namespace: 'application' })
  setStepTwoValid!: (stepOneValid: boolean) => void

  get hasSelectedEnlisteeType (): boolean {
    return this.applicationState.enlisteeType !== ''
  }

  public $refs!: {
    form: Student | Employee | Pupil | Apprentice;
  }

  get enlisteeForm (): string {
    switch (this.applicationState.enlisteeType) {
      case 'employee':
        return 'Employee'
      case 'student':
        return 'Student'
      case 'apprentice':
        return 'Apprentice'
      case 'pupil':
        return 'Pupil'
      default:
        return ''
    }
  }

  async goToNextStep () {
    if (!this.hasSelectedEnlisteeType) {
      return
    }
    const formIsValid = await this.$refs.form.isValid()
    if (formIsValid) {
      this.setCurrentStep(2)
      this.setStepTwoValid(true)
      this.$router.push({ path: '/step3' })
    }
  }

  goToPreviousStep (): void {
    this.$router.push({ path: '/step1' })
  }
}
