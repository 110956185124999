import { MutationTree } from 'vuex'
import { ApplicationState, MutationTypes, Person, Employer } from './types'
import { PhoneNumber } from '../../types'

export type Mutations<S = ApplicationState> = {
  [MutationTypes.SET_IS_POST_AND_FINANCE_UNION_MEMBER](state: S, payload: boolean): void;
  [MutationTypes.SET_SELECTED_POST_AND_FINANCE_UNION_COMPANY](state: S, payload: boolean): void;

  [MutationTypes.SET_REFERRER_IDENTIFIER](state: S, payload: string | null): void;
  [MutationTypes.SET_REFERER_NAME](state: S, payload: string | null): void;

  [MutationTypes.SET_PERSON](state: S, payload: string): void;
  [MutationTypes.SET_FIRSTNAME](state: S, payload: string): void;
  [MutationTypes.SET_LASTNAME](state: S, payload: string): void;
  [MutationTypes.SET_ADDRESS](state: S, payload: string): void;
  [MutationTypes.SET_ZIPCODE](state: S, payload: string): void;
  [MutationTypes.SET_AREA](state: S, payload: string): void;
  [MutationTypes.SET_NATIONAL_ID_NUMBER](state: S, payload: string): void;
  [MutationTypes.SET_EMAIL](state: S, payload: string): void;
  [MutationTypes.SET_PHONE](state: S, payload: PhoneNumber): void;
  [MutationTypes.SET_ENLISTEE_TYPE](state: S, payload: string): void;

  // Student
  [MutationTypes.SET_STUDY_AREA](state: S, payload: string): void;
  [MutationTypes.SET_STUDY_PLACE](state: S, payload: string): void;
  [MutationTypes.SET_STUDY_SUBJECT](state: S, payload: string): void;
  [MutationTypes.SET_STUDY_FINISHED_WHEN](state: S, payload: string): void;

  // Apprentice
  [MutationTypes.SET_APPRENTICE_SUBJECT](state: S, payload: string): void;
  [MutationTypes.SET_APPRENTICE_STUDY_AREA](state: S, payload: string): void;
  [MutationTypes.SET_APPRENTICE_EMPLOYER](state: S, payload: string): void;
  [MutationTypes.SET_APPRENTICE_FINISHED_WHEN](state: S, payload: string): void;
  [MutationTypes.SET_APPRENTICE_INCLUDE_INSURANCE](state: S, payload: boolean): void;

  // Pupil
  [MutationTypes.SET_PUPIL_STUDY_DIRECTION](state: S, payload: string): void;
  [MutationTypes.SET_PUPIL_SCHOOL](state: S, payload: string): void;
  [MutationTypes.SET_PUPIL_STUDY_AREA](state: S, payload: string): void;
  [MutationTypes.SET_PUPIL_FINISHED_WHEN](state: S, payload: string): void;
  [MutationTypes.SET_PUPIL_INCLUDE_INSURANCE](state: S, payload: boolean): void;

  // Other union
  [MutationTypes.SET_FORWARD_TO_OTHER_UNION](state: S, payload: number): void;
  [MutationTypes.SET_MEMBER_OTHER_UNION](state: S, payload: number): void;
  [MutationTypes.SET_OTHER_UNION](state: S, payload: string | null): void;

  [MutationTypes.SET_HAS_TWO_EPLOYERS](state: S, payload: boolean): void;
  [MutationTypes.SET_POSTKOM_EMPLOYER](state: S, payload: string | null): void;
  [MutationTypes.SET_POSTKOM_OTHER_EMPLOYER](state: S, payload: string): void;
  [MutationTypes.SET_EMPLOYER](state: S, payload: string | null): void;
  [MutationTypes.SET_EMPLOYEE_NUMBER](state: S, payload: string | null): void;
  [MutationTypes.SET_OTHER_EMPLOYER](state: S, payload: string): void;
  [MutationTypes.SET_WORKPLACE](state: S, payload: string): void;
  [MutationTypes.SET_PROFESSION_UUID](state: S, payload: string | null): void;
  [MutationTypes.SET_OTHER_PROFESSION](state: S, payload: string): void;
  [MutationTypes.SET_POSITION](state: S, payload: string): void;
  [MutationTypes.SET_WORKPLACE_PERCENTAGE](state: S, payload: string): void;

  [MutationTypes.SET_EMPLOYER_2](state: S, payload: string | null): void;
  [MutationTypes.SET_OTHER_EMPLOYER_2](state: S, payload: string): void;
  [MutationTypes.SET_WORKPLACE_2](state: S, payload: string): void;
  [MutationTypes.SET_PROFESSION_UUID_2](state: S, payload: string | null): void;
  [MutationTypes.SET_OTHER_PROFESSION_2](state: S, payload: string): void;
  [MutationTypes.SET_POSITION_2](state: S, payload: string): void;
  [MutationTypes.SET_WORKPLACE_PERCENTAGE_2](state: S, payload: string): void;

  [MutationTypes.SET_WAS_ENLISTED](state: S, payload: boolean): void;
  [MutationTypes.SET_ENLISTER_MEMBER_NR](state: S, payload: string): void;
  [MutationTypes.SET_ENLISTER_FIRSTNAME](state: S, payload: string): void;
  [MutationTypes.SET_ENLISTER_LASTNAME](state: S, payload: string): void;
  [MutationTypes.SET_ENLISTER_ADDRESS](state: S, payload: string): void;
  [MutationTypes.SET_ENLISTER_ZIPCODE](state: S, payload: string): void;
  [MutationTypes.SET_ENLISTER_AREA](state: S, payload: string): void;
  [MutationTypes.SET_ENLISTER_PHONE](state: S, payload: PhoneNumber): void;
  [MutationTypes.SET_ENLISTER_EMAIL](state: S, payload: string): void;

  [MutationTypes.SET_EXTRA_INFO](state: S, payload: string): void;
  [MutationTypes.SET_RESERVE_AGAINST_TRAVEL_INSURANCE](state: S, payload: boolean): void;
  [MutationTypes.SET_RESERVE_AGAINST_WELCOME_GIFT](state: S, payload: boolean): void;
  [MutationTypes.SET_CURRENT_STEP](state: S, payload: number): void;

  [MutationTypes.SET_SHOW_ADDRESS_FORM](state: S, payload: boolean): void;
  [MutationTypes.SET_STEP_ONE_VALID](state: S, payload: boolean): void;
  [MutationTypes.SET_STEP_TWO_VALID](state: S, payload: boolean): void;
  [MutationTypes.SET_STEP_THREE_VALID](state: S, payload: boolean): void;
  [MutationTypes.SET_STEP_FOUR_VALID](state: S, payload: boolean): void;
  [MutationTypes.SET_FOUND_PERSON_FROM_SEARCH](state: S, payload: boolean): void;
}

export const mutations: MutationTree<ApplicationState> = {
  [MutationTypes.SET_IS_POST_AND_FINANCE_UNION_MEMBER] (state, payload: boolean) {
    state.isPostAndFinanceUnionMember = payload
  },
  [MutationTypes.SET_SELECTED_POST_AND_FINANCE_UNION_COMPANY] (state, payload: boolean) {
    state.selectedPostAndFinanceUnionCompany = payload
  },
  [MutationTypes.SET_REFERRER_IDENTIFIER] (state, payload: string | null) {
    state.referrerIdentifier = payload
  },
  [MutationTypes.SET_REFERER_NAME] (state, payload: string | null) {
    state.referrerName = payload
  },
  [MutationTypes.SET_EMPLOYEE_NUMBER] (state, payload: string | null) {
    state.employeenumber = payload
  },
  [MutationTypes.SET_FIRSTNAME] (state, payload: string) {
    state.firstName = payload
  },
  [MutationTypes.SET_LASTNAME] (state, payload: string) {
    state.lastName = payload
  },
  [MutationTypes.SET_ADDRESS] (state, payload: string) {
    state.address = payload
  },
  [MutationTypes.SET_ZIPCODE] (state, payload: string) {
    state.postalCode = payload
  },
  [MutationTypes.SET_AREA] (state, payload: string) {
    state.postalArea = payload
  },
  [MutationTypes.SET_NATIONAL_ID_NUMBER] (state, payload: string) {
    state.nin = payload
  },
  [MutationTypes.SET_PHONE] (state, payload: PhoneNumber) {
    state.phone = payload
  },
  [MutationTypes.SET_EMAIL] (state, payload: string) {
    state.emailAddress = payload
  },
  [MutationTypes.SET_ENLISTEE_TYPE] (state, payload: string) {
    state.enlisteeType = payload
  },
  [MutationTypes.SET_STUDY_AREA] (state, payload: string) {
    state.studyArea = payload
  },
  [MutationTypes.SET_STUDY_PLACE] (state, payload: string) {
    state.studyPlace = payload
  },
  [MutationTypes.SET_STUDY_SUBJECT] (state, payload: string) {
    state.studySubject = payload
  },
  [MutationTypes.SET_STUDY_FINISHED_WHEN] (state, payload: string) {
    state.studentFinishedWhen = payload
  },
  // Apprentice
  [MutationTypes.SET_APPRENTICE_SUBJECT] (state, payload: string) {
    state.apprenticeSubject = payload
  },
  [MutationTypes.SET_APPRENTICE_STUDY_AREA] (state, payload: string) {
    state.apprenticeStudyArea = payload
  },
  [MutationTypes.SET_APPRENTICE_EMPLOYER] (state, payload: string) {
    state.apprenticeEmployer = payload
  },
  [MutationTypes.SET_APPRENTICE_FINISHED_WHEN] (state, payload: string) {
    state.apprenticeFinishedWhen = payload
  },
  [MutationTypes.SET_APPRENTICE_INCLUDE_INSURANCE] (state, payload: boolean) {
    state.apprenticeIncludeInsurance = payload
  },
  // Pupil
  [MutationTypes.SET_PUPIL_STUDY_DIRECTION] (state, payload: string) {
    state.pupilStudyDirection = payload
  },
  [MutationTypes.SET_PUPIL_SCHOOL] (state, payload: string) {
    state.pupilSchool = payload
  },
  [MutationTypes.SET_PUPIL_STUDY_AREA] (state, payload: string) {
    state.pupilStudyArea = payload
  },
  [MutationTypes.SET_PUPIL_FINISHED_WHEN] (state, payload: string) {
    state.pupilFinishedWhen = payload
  },
  [MutationTypes.SET_PUPIL_INCLUDE_INSURANCE] (state, payload: boolean) {
    state.pupilIncludeInsurance = payload
  },
  [MutationTypes.SET_FORWARD_TO_OTHER_UNION] (state, payload: boolean) {
    state.forwardOtherUnion = payload
  },
  [MutationTypes.SET_MEMBER_OTHER_UNION] (state, payload: boolean) {
    state.memberOfOtherUnion = payload
  },
  [MutationTypes.SET_OTHER_UNION] (state, payload: string | null) {
    state.otherUnion = payload
  },
  [MutationTypes.SET_COUNTY_UUID] (state, payload: string) {
    state.countyOrganizationalUnitUuid = payload
  },
  [MutationTypes.SET_POSTKOM_EMPLOYER] (state, payload: Employer | null) {
    state.postkomEmployer = payload
  },
  [MutationTypes.SET_POSTKOM_OTHER_EMPLOYER] (state, payload: Employer) {
    state.postkomOtherEmployer = payload
  },
  [MutationTypes.SET_EMPLOYER] (state, payload: Employer | null) {
    state.employer = payload
  },
  [MutationTypes.SET_OTHER_EMPLOYER] (state, payload: Employer) {
    state.employerOtherEmployer = payload
  },
  [MutationTypes.SET_HAS_TWO_EPLOYERS] (state, payload: boolean) {
    state.hasTwoEmployers = payload
  },
  [MutationTypes.SET_WORKPLACE] (state, payload: string) {
    state.workplace = payload
  },
  [MutationTypes.SET_PROFESSION_UUID] (state, payload: string | null) {
    state.professionUuid = payload
  },
  [MutationTypes.SET_OTHER_PROFESSION] (state, payload: string) {
    state.otherProfession = payload
  },
  [MutationTypes.SET_POSITION] (state, payload: string) {
    state.position = payload
  },
  [MutationTypes.SET_WORKPLACE_PERCENTAGE] (state, payload: number) {
    state.workplacePercentage = payload
  },
  [MutationTypes.SET_EMPLOYER_2] (state, payload: Employer | null) {
    state.employer2 = payload
  },
  [MutationTypes.SET_OTHER_EMPLOYER_2] (state, payload: Employer) {
    state.employer2OtherEmployer = payload
  },
  [MutationTypes.SET_WORKPLACE_2] (state, payload: string) {
    state.workplace2 = payload
  },
  [MutationTypes.SET_PROFESSION_UUID_2] (state, payload: string | null) {
    state.professionUuid2 = payload
  },
  [MutationTypes.SET_OTHER_PROFESSION_2] (state, payload: string) {
    state.otherProfession2 = payload
  },
  [MutationTypes.SET_POSITION_2] (state, payload: string) {
    state.position2 = payload
  },
  [MutationTypes.SET_WORKPLACE_PERCENTAGE_2] (state, payload: number) {
    state.workplacePercentage2 = payload
  },
  [MutationTypes.SET_WAS_ENLISTED] (state, payload: boolean) {
    state.wasEnlisted = payload
  },
  [MutationTypes.SET_RESERVE_AGAINST_TRAVEL_INSURANCE] (state, payload: boolean) {
    state.reservedTravelInsurance = payload
  },
  [MutationTypes.SET_RESERVE_AGAINST_WELCOME_GIFT] (state, payload: boolean) {
    state.reservedWelcomeGift = payload
  },
  [MutationTypes.SET_ENLISTER_MEMBER_NR] (state, payload: string) {
    state.enlisterMemberNr = payload
  },
  [MutationTypes.SET_ENLISTER_FIRSTNAME] (state, payload: string) {
    state.enlisterFirstname = payload
  },
  [MutationTypes.SET_ENLISTER_LASTNAME] (state, payload: string) {
    state.enlisterLastname = payload
  },
  [MutationTypes.SET_ENLISTER_ADDRESS] (state, payload: string) {
    state.enlisterAddress = payload
  },
  [MutationTypes.SET_ENLISTER_ZIPCODE] (state, payload: string) {
    state.enlisterZipCode = payload
  },
  [MutationTypes.SET_ENLISTER_AREA] (state, payload: string) {
    state.enlisterArea = payload
  },
  [MutationTypes.SET_ENLISTER_PHONE] (state, payload: PhoneNumber) {
    state.enlisterPhone = payload
  },
  [MutationTypes.SET_ENLISTER_EMAIL] (state, payload: string) {
    state.enlisterEmail = payload
  },
  [MutationTypes.SET_EXTRA_INFO] (state, payload: string) {
    state.note = payload
  },
  [MutationTypes.SET_CURRENT_STEP] (state, payload: number) {
    state.currentStep = payload
  },
  [MutationTypes.SET_STEP_ONE_VALID] (state, payload: boolean) {
    state.stepOneValid = payload
  },
  [MutationTypes.SET_STEP_TWO_VALID] (state, payload: boolean) {
    state.stepTwoValid = payload
  },
  [MutationTypes.SET_STEP_THREE_VALID] (state, payload: boolean) {
    state.stepThreeValid = payload
  },
  [MutationTypes.SET_STEP_FOUR_VALID] (state, payload: boolean) {
    state.stepFourValid = payload
  },
  [MutationTypes.SET_SHOW_ADDRESS_FORM] (state, payload: boolean) {
    state.showAddressForm = payload
  },
  [MutationTypes.SET_FOUND_PERSON_FROM_SEARCH] (state, payload: boolean) {
    state.foundPersonFromSearch = payload
  },
  [MutationTypes.SET_PERSON] (state, payload: Person) {
    state.firstName = payload.firstName
    state.lastName = payload.lastName
    state.address = payload.address
    state.postalCode = payload.postalCode
    state.postalArea = payload.postalArea
  }
}
