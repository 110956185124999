/* eslint-disable @typescript-eslint/camelcase */
import { extend, localize } from 'vee-validate'
import { numeric, length, min_value, max_value, required, max } from 'vee-validate/dist/rules'
import IdApiService from './services/IdApiService'
import { validateNin } from '@halloverden/number-utilities-ts'
import { PhoneNumber } from './types'
localize({
  en: {
    messages: {
      required: 'this field is required',
      max: 'This field only allows {length} characters',
      min_value: 'Lowest possible value is 1',
      max_value: 'Highest possible value is 100',
      email: 'The email address is not valid',
      numeric: 'Enter digists only',
      length: 'This field requires {length} digits',
      phonenumber: 'Enter a valid phonenumber',
      mobilephonenumber: 'Enter a valid phonenumber',
      nationalidentificationnumber: 'National identification number is not valid'
    }
  },
  no: {
    messages: {
      required: 'Dette feltet må fylles ut',
      max: 'Maks antall bokstaver for dette feltet er {length}',
      min_value: 'Minste mulige verdi er 1',
      max_value: 'Høyeste mulige verdi er 100',
      email: 'E-postadressen er ikke gyldig',
      numeric: 'Tast bare tall',
      length: 'Dette feltet krever {length} siffer',
      phonenumber: 'Fyll ut et gyldig telefonnummer',
      mobilephonenumber: 'Fyll ut et gyldig telefonnummer',
      nationalidentificationnumber: 'Fødselsnummeret er ikke gyldig'
    }
  }
})

extend('max', max)

extend('min_value', min_value)

extend('max_value', max_value)

extend('numeric', numeric)

extend('length', length)

extend('required', {
  ...required,
  validate (value) {
    return {
      required: true,
      valid: ['', null, undefined].indexOf(value) === -1
    }
  },
  computesRequired: true
})

extend('email', {
  validate: async (value: string) => {
    try {
      return !value.includes('@')
        ? false
        : (await IdApiService.validateEmail(value))?.data.valid ?? false
    } catch (err) {
      return false
    }
  }
})

extend('mobilephonenumber', {
  validate: async (value: PhoneNumber) => {
    const phone = `${value.countryCode}${value.phoneNumber}`
    try {
      return (
        (await IdApiService.validatePhone(phone))?.data.valid ?? false
      )
    } catch (err) {
      return false
    }
  }
})

extend('nationalidentificationnumber', {
  validate (value) {
    return validateNin(value)
  }
})

localize('no')
