import { GetterTree } from 'vuex'
import {
  ApplicationState,
  MembershipApplication,
  Occupation,
  Employment
} from './types'
import { RootState } from '../types'

export const getters: GetterTree<ApplicationState, RootState> = {
  stepOneValid: (state): boolean => {
    return state.stepOneValid
  },
  stepTwoValid: (state): boolean => {
    return state.stepTwoValid
  },
  stepThreeValid: (state): boolean => {
    return state.stepThreeValid
  },
  stepFourValid: (state): boolean => {
    return state.stepFourValid
  },
  isPostAndFinanceUnionMember: (state): boolean => {
    return state.isPostAndFinanceUnionMember
  },
  hasSelectedPostAndFinanceUnionCompany: (state): boolean => {
    return state.selectedPostAndFinanceUnionCompany
  },
  getOccupationFormData: (state, getters): Occupation => {
    const { countyOrganizationalUnitUuid } = state
    switch (state.enlisteeType) {
      case 'pupil': {
        const {
          pupilStudyDirection,
          pupilSchool,
          pupilFinishedWhen,
          pupilIncludeInsurance
        } = state

        return {
          type: 'pupil',
          countyOrganizationalUnitUuid,
          studyDirection: pupilStudyDirection,
          schoolName: pupilSchool,
          endPeriod: pupilFinishedWhen,
          includeInsurance: pupilIncludeInsurance
        }
      }
      case 'apprentice': {
        const {
          apprenticeSubject,
          apprenticeFinishedWhen,
          apprenticeIncludeInsurance,
          postkomOtherEmployer,
          employerOtherEmployer
        } = state

        const postkomEmployer = postkomOtherEmployer ?? state.postkomEmployer
        const employer = employerOtherEmployer ?? state.employer

        return {
          type: 'apprentice',
          countyOrganizationalUnitUuid,
          subjectName: apprenticeSubject,
          employer: getters.isPostAndFinanceUnionMember
            ? postkomEmployer
            : employer,

          endPeriod: apprenticeFinishedWhen,
          includeInsurance: apprenticeIncludeInsurance
        }
      }
      case 'student': {
        const { studySubject, studyPlace, studentFinishedWhen, studyArea } =
          state
        return {
          type: 'student',
          countyOrganizationalUnitUuid,
          subjectName: studySubject,
          studyOrganizationName: studyPlace,
          endPeriod: studentFinishedWhen,
          studyPlaceName: studyArea
        }
      }
      case 'employee': {
        const {
          hasTwoEmployers,
          position,
          position2,
          workplace,
          workplace2,
          employerOtherEmployer,
          employer2OtherEmployer,
          postkomOtherEmployer,
          professionUuid,
          professionUuid2,
          workplacePercentage,
          workplacePercentage2,
          employeenumber
        } = state

        const postkomEmployer = postkomOtherEmployer ?? state.postkomEmployer
        const employer = employerOtherEmployer ?? state.employer

        const mainEmployment: Employment = {
          employer: getters.isPostAndFinanceUnionMember
            ? postkomEmployer
            : employer,
          workplace: {
            name: workplace
          },
          ...(position && { position: { name: position } }),
          professionUuid: professionUuid,
          employmentPercentage: workplacePercentage
        }
        if (
          (getters.isPostAndFinanceUnionMember ||
            getters.hasSelectedPostAndFinanceUnionCompany) &&
          employeenumber
        ) {
          mainEmployment.employeeNumber = employeenumber
        }
        const employments = Array<Employment>(mainEmployment)
        const employer2 = employer2OtherEmployer ?? state.employer2

        if (hasTwoEmployers) {
          const secondaryEmployment: Employment = {
            employer: employer2,
            workplace: {
              name: workplace2
            },
            professionUuid: professionUuid2,
            ...(position2 && { position: { name: position2 } }),
            employmentPercentage: workplacePercentage2
          }
          employments.push(secondaryEmployment)
        }
        return {
          type: 'employee',
          countyOrganizationalUnitUuid,
          employments: employments
        }
      }
      default:
        return {} as Occupation
    }
  },
  getFormData: (state, getters): MembershipApplication => {
    const {
      nin,
      firstName,
      lastName,
      phone,
      address: line1,
      postalCode,
      postalArea,
      countryCode,
      reservedTravelInsurance,
      reservedWelcomeGift,
      memberOfOtherUnion,
      otherUnion,
      forwardOtherUnion,
      emailAddress,
      note,
      wasEnlisted,
      referrerIdentifier,
      enlisterEmail,
      enlisterPhone
    } = state

    const application: MembershipApplication = {
      nin,
      firstName,
      lastName,
      phoneNumber: `${phone.countryCode}${phone.phoneNumber}`,
      postalAddress: {
        line1,
        postalCode,
        postalArea,
        countryCode
      },
      occupation: getters.getOccupationFormData,
      agreeToBeContacted: true,
      reservedTravelInsurance,
      reservedWelcomeGift,
      memberOfOtherUnion,
      otherUnion,
      forwardOtherUnion,
      emailAddress,
      ...(memberOfOtherUnion && {
        otherUnion,
        forwardOtherUnion
      }),
      ...(note && { note })
    }

    if (wasEnlisted) {
      if (referrerIdentifier) {
        application.referral = {
          emailAddress: null,
          phoneNumber: null,
          referrerIdentifier
        }
      } else {
        application.referral = {
          referrerIdentifier: null,
          emailAddress: enlisterEmail || null,
          phoneNumber:
            `${enlisterPhone.countryCode}${enlisterPhone.phoneNumber}` || null
        }
      }
    }
    return application
  }
}
