
/* Decorators */
import { Component, Vue } from 'vue-property-decorator'
import { State, Mutation } from 'vuex-class'

/* Components */
import {
  FormRow,
  FormTextField,
  FormSelect,
  FormSelectOption,
  Icon
} from '@fagforbundet/components'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import County from '../County.vue'

/* Types */
import {
  ApplicationState,
  MutationTypes
} from '../../../../store/application/types'

/* Vendor */
import dayjs from 'dayjs'

@Component({
  components: {
    Icon,
    FormRow,
    FormSelect,
    FormSelectOption,
    FormTextField,
    ValidationProvider,
    ValidationObserver,
    County
  }
})
export default class Student extends Vue {
  @State('application') applicationState!: ApplicationState
  @Mutation(MutationTypes.SET_STUDY_SUBJECT, { namespace: 'application' })
  setStudySubject!: (studySubject: string) => void

  @Mutation(MutationTypes.SET_STUDY_AREA, { namespace: 'application' })
  setStudyArea!: (studyArea: string) => void

  @Mutation(MutationTypes.SET_STUDY_PLACE, { namespace: 'application' })
  setStudyPlace!: (setStudyPlace: string) => void

  @Mutation(MutationTypes.SET_STUDY_FINISHED_WHEN, { namespace: 'application' })
  setFinishedWhen!: (finishedWhen: string) => void

  private mounted (): void {
    console.log('Student::mounted()')
  }

  public $refs!: {
    observer: InstanceType<typeof ValidationObserver>;
    county: County;
  }

  get semesters (): string[] {
    const data: string[] = []

    let date = dayjs()
    for (let i = 0; i < 10; i++) {
      const semester =
        date.month() >= 6
          ? this.$t('global.fall').toString()
          : this.$t('global.spring').toString()
      const year = date.year()
      data.push(`${year} – ${semester}`)

      date = date.add(6, 'month')
    }

    return data
  }

  get studySubject (): string {
    return this.applicationState.studySubject ?? ''
  }

  set studySubject (studySubject: string) {
    this.setStudySubject(studySubject)
  }

  get studyPlace (): string {
    return this.applicationState.studyPlace ?? ''
  }

  set studyPlace (studyPlace: string) {
    this.setStudyPlace(studyPlace)
    this.setStudyArea(studyPlace) // studyArea does not have an input field currently, but required to be sent in
  }

  get studyArea (): string {
    return this.applicationState.studyArea ?? ''
  }

  set studyArea (studyArea: string) {
    this.setStudyArea(studyArea)
  }

  get finishedWhen (): string {
    return this.applicationState.studentFinishedWhen ?? ''
  }

  set finishedWhen (finishedWhen: string) {
    this.setFinishedWhen(finishedWhen)
  }

  public async isValid () {
    const countyIsValid = await this.$refs.county.isValid()
    const isValid = await this.$refs.observer.validate()
    return isValid && countyIsValid
  }
}
