import { configure } from 'vee-validate'
import { Icons } from '@fagforbundet/svgs'
import joinIcons from './lib/icons'
import Rollbar from 'rollbar'
import { Globals } from './Globals'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { i18n } from './i18n'
import VueCookieBot from '@ambitiondev/vue-cookiebot-plugin'
import './scss/application.scss'
import './validation'
import { CookieBot } from '@ambitiondev/vue-cookiebot-plugin/dist/types/cookiebot'

Vue.use(VueCookieBot, {
  cookieBotID: '36cd9234-248d-473f-9956-3c5f2d3fae9d'
})

const config = {
  classes: {
    valid: 'has-success',
    invalid: 'has-error'
  }
}

// Sets the options.
configure(config)

/*
Object.keys(joinIcons).forEach((name) => {
  Icons.add(name, joinIcons[name]);
});
*/

// Add extra types to Vue
declare module 'vue/types/vue' {
  interface Vue {
    $rollbar: Rollbar;
    $cookiebot: CookieBot;
  }
}

Vue.prototype.$rollbar = new Rollbar({
  accessToken: Globals.ROLLBAR_ACCESS_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true
})

Vue.config.errorHandler = (err: Error, vm: Vue, info: string) => {
  vm.$rollbar.error(err, info)
  throw err
}

for (const [key, value] of Object.entries(joinIcons)) {
  Icons.add(key, value)
}

Vue.config.productionTip = false

new Vue({
  store,
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
