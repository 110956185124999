
/* Decorators */
import { Component, Vue } from 'vue-property-decorator'
import { State, Mutation } from 'vuex-class'

/* Components */
import {
  FormRow,
  FormTextField,
  FormColumn,
  FormPhoneNumberField,
  FormCheckbox,
  FormButton
} from '@fagforbundet/components'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
/* Types */
import {
  ApplicationState,
  MutationTypes
} from '../../../store/application/types'
import { PhoneNumber } from '../../../types'

@Component({
  components: {
    FormRow,
    FormColumn,
    FormTextField,
    FormPhoneNumberField,
    FormCheckbox,
    FormButton,
    ValidationProvider,
    ValidationObserver
  }
})
export default class EnlisterForm extends Vue {
  @State('application') applicationState!: ApplicationState
  @Mutation(MutationTypes.SET_ENLISTER_MEMBER_NR, { namespace: 'application' })
  setEnlisterMemberNr!: (enlisterMemberNr: string) => void

  @Mutation(MutationTypes.SET_ENLISTER_FIRSTNAME, { namespace: 'application' })
  setEnlisterFirstname!: (enlisterFirstname: string) => void

  @Mutation(MutationTypes.SET_ENLISTER_LASTNAME, { namespace: 'application' })
  setEnlisterLastname!: (enlisterLastname: string) => void

  @Mutation(MutationTypes.SET_ENLISTER_ADDRESS, { namespace: 'application' })
  setEnlisterAddress!: (enlisterAddress: string) => void

  @Mutation(MutationTypes.SET_ENLISTER_ZIPCODE, { namespace: 'application' })
  setEnlisterZipCode!: (enlisterZipCode: string) => void

  @Mutation(MutationTypes.SET_ENLISTER_AREA, { namespace: 'application' })
  setEnlisterArea!: (enlisterArea: string) => void

  @Mutation(MutationTypes.SET_ENLISTER_PHONE, { namespace: 'application' })
  setEnlisterPhone!: (enlisterPhone: PhoneNumber) => void

  @Mutation(MutationTypes.SET_ENLISTER_EMAIL, { namespace: 'application' })
  setEnlisterEmail!: (enlisterEmail: string) => void

  @Mutation(MutationTypes.SET_WAS_ENLISTED, { namespace: 'application' })
  setWasEnlisted!: (wasEnlisted: boolean) => void

  @Mutation(MutationTypes.SET_REFERRER_IDENTIFIER, { namespace: 'application' })
  setRefererIdentifier!: (identifier: string | null) => void

  @Mutation(MutationTypes.SET_REFERER_NAME, { namespace: 'application' })
  setRefererName!: (name: string | null) => void

  public $refs!: {
    observer: InstanceType<typeof ValidationObserver>;
  }

  private mounted (): void {
    console.log('EnlisterForm::mounted()')
  }

  get wasEnlisted (): boolean {
    return this.applicationState.wasEnlisted
  }

  set wasEnlisted (wasEnlisted: boolean) {
    this.setWasEnlisted(wasEnlisted)
  }

  get hasReferrerName (): boolean {
    return Boolean(
      this.applicationState.referrerIdentifier &&
        this.applicationState.referrerName
    )
  }

  get ververLabel (): string {
    if (this.hasReferrerName) {
      return `${this.$t('enlisted.iwasenlistedby').toString()} ${
        this.referrerName
      }.`
    }
    return this.$t('enlisted.iwasenlisted').toString()
  }

  get referrerName (): string {
    return this.applicationState.referrerName ?? ''
  }

  get enlisterMemberNr (): string {
    return this.applicationState.enlisterMemberNr
  }

  set enlisterMemberNr (enlisterMemberNr: string) {
    this.setEnlisterMemberNr(enlisterMemberNr)
  }

  get enlisterFirstname (): string {
    return this.applicationState.enlisterFirstname ?? ''
  }

  set enlisterFirstname (enlisterFirstname: string) {
    this.setEnlisterFirstname(enlisterFirstname)
  }

  get enlisterLastname (): string {
    return this.applicationState.enlisterLastname ?? ''
  }

  set enlisterLastname (enlisterLastname: string) {
    this.setEnlisterLastname(enlisterLastname)
  }

  get enlisterAddress (): string {
    return this.applicationState.enlisterAddress ?? ''
  }

  set enlisterAddress (enlisterAddress: string) {
    this.setEnlisterAddress(enlisterAddress)
  }

  get enlisterZipCode (): string {
    return this.applicationState.enlisterZipCode ?? ''
  }

  set enlisterZipCode (enlisterZipCode: string) {
    this.setEnlisterZipCode(enlisterZipCode)
  }

  get enlisterArea (): string {
    return this.applicationState.enlisterArea ?? ''
  }

  set enlisterArea (enlisterArea: string) {
    this.setEnlisterArea(enlisterArea)
  }

  get enlisterPhone (): PhoneNumber {
    return this.applicationState.enlisterPhone
  }

  set enlisterPhone (enlisterPhone: PhoneNumber) {
    this.setEnlisterPhone(enlisterPhone)
  }

  get enlisterEmail (): string {
    return this.applicationState.enlisterEmail ?? ''
  }

  set enlisterEmail (enlisterEmail: string) {
    this.setEnlisterEmail(enlisterEmail)
  }

  removeReferrer (): void {
    this.setRefererIdentifier(null)
    this.setRefererName(null)
  }

  public async isValid (silent = false) {
    if (!this.wasEnlisted || (this.wasEnlisted && this.hasReferrerName)) {
      return true
    }
    const isValid = await this.$refs.observer.validate({ silent })
    return isValid
  }
}
