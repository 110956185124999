
/* Decorators */
import { Component, Vue, Watch } from 'vue-property-decorator'
import { State, Mutation, Action, Getter } from 'vuex-class'

/* Components */
import {
  FormRow,
  FormColumn,
  FormTextField,
  FormSelect,
  FormSelectOption,
  FormTypeaheadSelect
} from '@fagforbundet/components'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

/* Types */
import {
  ApplicationState,
  MutationTypes
} from '../../../store/application/types'
import { ContentState } from '@/store/content/types'
import { FormTypeaheadOption } from '../../../types'

@Component({
  components: {
    FormRow,
    FormColumn,
    FormTextField,
    FormSelect,
    FormTypeaheadSelect,
    FormSelectOption,
    ValidationProvider,
    ValidationObserver
  }
})
export default class Address extends Vue {
  @State('application') applicationState!: ApplicationState
  @State('content') contentState!: ContentState

  @Action('fetchCountries', { namespace: 'content' })
  fetchCountries!: (locale: 'nb-NO' | 'en-us') => Promise<void>

  @Getter('countries', { namespace: 'content' })
  countryList!: FormTypeaheadOption[]

  @Mutation(MutationTypes.SET_ADDRESS, { namespace: 'application' })
  setAddress!: (address: string) => void

  @Mutation(MutationTypes.SET_ZIPCODE, { namespace: 'application' })
  setZipcode!: (zipCode: string) => void

  @Mutation(MutationTypes.SET_AREA, { namespace: 'application' }) setArea!: (
    area: string
  ) => void

  public $refs!: {
    observer: InstanceType<typeof ValidationObserver>;
    countryTypeahead: InstanceType<typeof FormTypeaheadSelect>;
  }

  private mounted (): void {
    if (!this.contentState.countries) {
      this.fetchLocalizedCountries(this.locale, 'NO')
    }
  }

  get address (): string {
    return this.applicationState.address ?? ''
  }

  set address (address: string) {
    this.setAddress(address)
  }

  get zipCode (): string {
    return this.applicationState.postalCode ?? ''
  }

  set zipCode (zipCode: string) {
    this.setZipcode(zipCode)
  }

  get area (): string {
    return this.applicationState.postalArea ?? ''
  }

  set area (area: string) {
    this.setArea(area)
  }

  get countryCode (): string | null {
    return this.applicationState.countryCode
  }

  set countryCode (value: string | null) {
    this.applicationState.countryCode = value
  }

  public async isValid () {
    const isValid = await this.$refs.observer.validate()
    return isValid
  }

  get locale (): string {
    return this.$root.$i18n.locale
  }

  private fetchLocalizedCountries (
    locale: string,
    countryCodeToSelect: string | null
  ) {
    this.fetchCountries(locale === 'no' ? 'nb-NO' : 'en-us').then(() => {
      const { countryTypeahead } = this.$refs
      const index = countryTypeahead.options.findIndex(
        (country: FormTypeaheadOption) => country.id === countryCodeToSelect
      )
      countryTypeahead.select(index)
    })
  }

  @Watch('locale')
  localeChanged (locale: string) {
    this.fetchLocalizedCountries(locale, this.countryCode)
  }
}
