
/* Decorators */
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({})
export default class Progress extends Vue {
  @Prop({ type: Number, required: true }) step!: number

  steps = [
    'progress.personalinformation',
    'progress.membership',
    'progress.otherinformation'
  ]

  private mounted (): void {
    console.log('Progress::mounted()')
  }

  get isButtonDisbled () {
    return (index: number): boolean => {
      return index > this.step - 1
    }
  }

  get buttonLabel () {
    return (index: number): string => {
      return this.steps[index] ?? ''
    }
  }

  get linkToStep () {
    return (index: number): string => {
      return `/step${index + 1}`
    }
  }
}
