import { ActionTree, ActionContext } from 'vuex'
import { ApplicationState, ActionTypes, MutationTypes } from './types'
import { RootState } from '../types'
import { Mutations } from './mutations'
import { AxiosResponse } from 'axios'

import MembershipApiService from '../../services/MembershipApiService'

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<ApplicationState, RootState>, 'commit'>

export interface Actions {
  [ActionTypes.CLEAR_STATE]({ commit }: AugmentedActionContext): Promise<void>;
  [ActionTypes.FETCH_REFERRER]({ commit }: AugmentedActionContext): Promise<void>;
}

export const actions: ActionTree<ApplicationState, RootState> = {
  [ActionTypes.FETCH_REFERRER] ({ commit, state }) {
    if (!state.referrerIdentifier) {
      return
    }
    MembershipApiService.getReferrerNameFromIdentifier(state.referrerIdentifier)
      .then((resp: AxiosResponse) => {
        if (resp.status === 200) {
          const payload: string = resp && resp.data && resp.data.referrerName
          commit(MutationTypes.SET_REFERER_NAME, payload)
        } else {
          commit(MutationTypes.SET_REFERRER_IDENTIFIER, null)
        }
      }, () => {
        commit(MutationTypes.SET_REFERER_NAME, null)
        commit(MutationTypes.SET_REFERRER_IDENTIFIER, null)
      })
  },
  [ActionTypes.CLEAR_STATE] ({ commit }) {
    commit(MutationTypes.SET_IS_POST_AND_FINANCE_UNION_MEMBER, false)
    commit(MutationTypes.SET_SELECTED_POST_AND_FINANCE_UNION_COMPANY, false)
    commit(MutationTypes.SET_REFERRER_IDENTIFIER, null)
    commit(MutationTypes.SET_REFERER_NAME, null)
    commit(MutationTypes.SET_FOUND_PERSON_FROM_SEARCH, null)
    commit(MutationTypes.SET_FIRSTNAME, '')
    commit(MutationTypes.SET_LASTNAME, '')
    commit(MutationTypes.SET_ADDRESS, '')
    commit(MutationTypes.SET_EMAIL, '')
    commit(MutationTypes.SET_ZIPCODE, '')
    commit(MutationTypes.SET_AREA, '')
    commit(MutationTypes.SET_PHONE, { countryCode: '+47', phoneNumber: '' })
    commit(MutationTypes.SET_COUNTY_UUID, '')
    commit(MutationTypes.SET_NATIONAL_ID_NUMBER, '')
    commit(MutationTypes.SET_ENLISTEE_TYPE, '')

    commit(MutationTypes.SET_FORWARD_TO_OTHER_UNION, true)
    commit(MutationTypes.SET_MEMBER_OTHER_UNION, false)
    commit(MutationTypes.SET_OTHER_UNION, null)

    commit(MutationTypes.SET_STUDY_AREA, '')
    commit(MutationTypes.SET_STUDY_SUBJECT, '')
    commit(MutationTypes.SET_STUDY_PLACE, '')
    commit(MutationTypes.SET_STUDY_FINISHED_WHEN, '')

    commit(MutationTypes.SET_APPRENTICE_SUBJECT, '')
    commit(MutationTypes.SET_APPRENTICE_STUDY_AREA, '')
    commit(MutationTypes.SET_APPRENTICE_EMPLOYER, '')
    commit(MutationTypes.SET_APPRENTICE_FINISHED_WHEN, '')
    commit(MutationTypes.SET_APPRENTICE_INCLUDE_INSURANCE, false)

    commit(MutationTypes.SET_PUPIL_STUDY_DIRECTION, '')
    commit(MutationTypes.SET_PUPIL_SCHOOL, '')
    commit(MutationTypes.SET_PUPIL_STUDY_AREA, '')
    commit(MutationTypes.SET_PUPIL_FINISHED_WHEN, '')
    commit(MutationTypes.SET_PUPIL_INCLUDE_INSURANCE, false)

    commit(MutationTypes.SET_HAS_TWO_EPLOYERS, false)
    commit(MutationTypes.SET_POSTKOM_EMPLOYER, null)
    commit(MutationTypes.SET_POSTKOM_OTHER_EMPLOYER, '')
    commit(MutationTypes.SET_EMPLOYER, null)
    commit(MutationTypes.SET_OTHER_EMPLOYER, '')
    commit(MutationTypes.SET_WORKPLACE, '')
    commit(MutationTypes.SET_EMPLOYEE_NUMBER, null)
    commit(MutationTypes.SET_PROFESSION_UUID, null)
    commit(MutationTypes.SET_OTHER_PROFESSION, '')
    commit(MutationTypes.SET_POSITION, '')
    commit(MutationTypes.SET_WORKPLACE_PERCENTAGE, 100)

    commit(MutationTypes.SET_EMPLOYER_2, null)
    commit(MutationTypes.SET_OTHER_EMPLOYER_2, '')
    commit(MutationTypes.SET_WORKPLACE_2, '')
    commit(MutationTypes.SET_PROFESSION_UUID_2, null)
    commit(MutationTypes.SET_OTHER_PROFESSION_2, '')
    commit(MutationTypes.SET_POSITION_2, '')
    commit(MutationTypes.SET_WORKPLACE_PERCENTAGE_2, 0)

    commit(MutationTypes.SET_WAS_ENLISTED, false)
    commit(MutationTypes.SET_RESERVE_AGAINST_TRAVEL_INSURANCE, false)
    commit(MutationTypes.SET_RESERVE_AGAINST_WELCOME_GIFT, false)
    commit(MutationTypes.SET_ENLISTER_EMAIL, '')
    commit(MutationTypes.SET_ENLISTER_PHONE, { countryCode: '+47', phoneNumber: '' })

    commit(MutationTypes.SET_EXTRA_INFO, '')

    commit(MutationTypes.SET_SHOW_ADDRESS_FORM, false)
    commit(MutationTypes.SET_STEP_ONE_VALID, false)
    commit(MutationTypes.SET_STEP_TWO_VALID, false)
    commit(MutationTypes.SET_STEP_THREE_VALID, false)
    commit(MutationTypes.SET_STEP_FOUR_VALID, false)
  }
}
