import { Employer } from './store/application/types'

export const postkomEmployerOrganizationNumbers = [
  979427107, // 'Bring Courier & Express AS',
  923542973, // 'Bring Home Delivery Norge AS',
  977207835, // 'Bring Transportløsninger AS',
  821465222, // 'Bring Warehousing AS',
  983419682, // 'Centurion AS',
  985621551, // 'DNB Boligkreditt AS',
  984851006, // 'DnB NOR Bank ASA',
  // 'DnB NOR Finans AS',
  // 'DnB NOR Meglerservice AS',
  // 'DnB NOR Skadeforsikring AS',
  971075252, 'Fagforbundet - administrativ arbeidsgiver',
  915651232, 'Fremtind',
  914791723, 'ISS Facility Services AS',
  971435704, 'LO Stat',
  // 'Norian IT Solution AS',
  984661185, // 'Posten Norge AS',
  982410339 // 'Visma Enterprice AS'
]

export const postkomEmployers: Employer[] = [
  {
    organizationNumber: '979427107',
    name: 'Bring Courier & Express AS'
  },
  {
    organizationNumber: '923542973',
    name: 'Bring Home Delivery Norge AS'
  },
  {
    organizationNumber: '977207835',
    name: 'Bring Transportløsninger AS'
  },
  {
    organizationNumber: '821465222',
    name: 'Bring Warehousing AS'
  },
  {
    organizationNumber: '983419682',
    name: 'Centurion AS'
  },
  {
    organizationNumber: '985621551',
    name: 'DNB Boligkreditt AS'
  },
  {
    organizationNumber: '984851006',
    name: 'DnB NOR Bank ASA'
  },
  {
    organizationNumber: '971075252',
    name: 'Fagforbundet - administrativ arbeidsgiver'
  },
  {
    organizationNumber: '914791723',
    name: 'ISS Facility Services AS'
  },
  {
    organizationNumber: '971435704',
    name: 'LO Stat'
  },
  {
    organizationNumber: '982410339',
    name: 'Visma Enterprice AS'
  }
]

export const Globals = {
  CLIENT_ID: process.env.VUE_APP_CLIENT_ID,
  API_URL: process.env.VUE_APP_MEMBERSHIP_SIGNUP_API_URL,
  ID_API_URL: process.env.VUE_APP_ID_API_URL,
  DIRECTUS_URL: process.env.VUE_APP_DIRECTUS_URL,
  PROXY_API_URL: process.env.VUE_APP_PROXY_API_URL,
  ROLLBAR_ACCESS_TOKEN: process.env.VUE_APP_ROLLBAR_ACCESS_TOKEN
}
