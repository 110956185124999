import axios, { AxiosPromise } from 'axios'
import { Globals } from '../Globals'
import { Profession } from '@/store/content/types'
import { OrganizationalUnit } from '@/store/content/types/OrganizationalUnit'
import { MembershipApplication } from '@/store/application/types'

export default {
  storeEnlistment (token: string, membershipApplication: MembershipApplication): AxiosPromise {
    return axios({
      url: `${Globals.API_URL}/v3/membership-applications`,
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token
      },
      data: {
        membershipApplication
      }
    })
  },
  getReferrerNameFromIdentifier (identifier: string): AxiosPromise {
    return axios({
      url: `${Globals.API_URL}/v1/users/by-referrer-identifier/${identifier}`,
      method: 'GET'
    })
  },
  getProfessions (): AxiosPromise<{ professions: Profession[] }> {
    return axios({
      method: 'GET',
      baseURL: Globals.API_URL,
      url: '/v1/professions'
    })
  },
  getOrganizationalUnits (
    token: string,
    params?: Partial<{
      limit: number;
      offset: number;
      name: string;
      types: string;
      include: string;
      filterName: string;
    }>
  ): AxiosPromise<{
    organizationalUnits: OrganizationalUnit[];
  }> {
    return axios({
      method: 'GET',
      baseURL: Globals.API_URL,
      url: '/v1/organizational-units',
      params,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
  }
}
