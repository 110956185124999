
/* Decorators */
import { Component, Vue } from 'vue-property-decorator'
import { State, Mutation } from 'vuex-class'

/* Types */
import { WorkStatus } from '../../../types'
import {
  ApplicationState,
  MutationTypes
} from '../../../store/application/types'

/* Components */
import WorkStatusItem from './WorkStatusItem.vue'

@Component({
  components: {
    WorkStatusItem
  }
})
export default class WorkStatusPicker extends Vue {
  @State('application') applicationState!: ApplicationState
  @Mutation(MutationTypes.SET_ENLISTEE_TYPE, { namespace: 'application' })
  setEnlisteeType!: (id: string) => void

  options: WorkStatus[] = [
    {
      id: 'employee',
      name: 'situation.employed'
    },
    {
      id: 'student',
      name: 'situation.student'
    },
    {
      id: 'apprentice',
      name: 'situation.apprentice'
    },
    {
      id: 'pupil',
      name: 'situation.pupil'
    }
  ]

  selectItem (id: string): void {
    this.setEnlisteeType(id)
  }
}
