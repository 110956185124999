
/* Decorators */
import { Component, Vue } from 'vue-property-decorator'
import { State, Mutation, Getter, Action } from 'vuex-class'

/* Components */
import {
  FormRow,
  FormColumn,
  FormRadio,
  FormRadioOption,
  FormTypeaheadSelect
} from '@fagforbundet/components'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

/* Types */
import {
  ApplicationState,
  MutationTypes
} from '../../../store/application/types'
import { FormTypeaheadOption } from '../../../types'
import { ContentState } from '../../../store/content/types'

@Component({
  components: {
    FormRow,
    FormColumn,
    FormRadio,
    FormRadioOption,
    ValidationProvider,
    ValidationObserver,
    FormTypeaheadSelect
  }
})
export default class OtherUnion extends Vue {
  @State('application') applicationState!: ApplicationState
  @State('content') contentState!: ContentState
  @Mutation(MutationTypes.SET_FORWARD_TO_OTHER_UNION, {
    namespace: 'application'
  })
  setForwardToOtherUnion!: (forwardToOtherUnion: boolean) => void

  @Mutation(MutationTypes.SET_MEMBER_OTHER_UNION, { namespace: 'application' })
  setMemberOtherUnion!: (memberOtherUnion: boolean) => void

  @Mutation(MutationTypes.SET_OTHER_UNION, { namespace: 'application' })
  setOtherUnion!: (otherUnion: string | null) => void

  @Action('fetchLoUnions', { namespace: 'content' })
  fetchLoUnions!: () => Promise<void>

  @Getter('loUnions', { namespace: 'content' }) loUnions!: FormTypeaheadOption[]

  public $refs!: {
    observer: InstanceType<typeof ValidationObserver>;
  }

  private mounted (): void {
    if (!this.contentState.loUnions) {
      // TODO update when union endpoint can return correct unions
      // this.fetchLoUnions()
    }
  }

  get unionsLoaded (): boolean {
    return this.loUnions.length > 0
  }

  get unionsList (): FormTypeaheadOption[] {
    return this.loUnions
  }

  get forwardToOtherUnion (): string {
    return this.applicationState.forwardOtherUnion ? '1' : '0'
  }

  set forwardToOtherUnion (forwardToOtherUnion: string) {
    this.setForwardToOtherUnion(Boolean(forwardToOtherUnion === '1'))
  }

  get memberOtherUnion (): string | null {
    if (this.applicationState.memberOfOtherUnion === null) {
      return null
    }
    return this.applicationState.memberOfOtherUnion ? '1' : '0'
  }

  set memberOtherUnion (memberOtherUnion: string | null) {
    this.setMemberOtherUnion(Boolean(memberOtherUnion === '1'))
  }

  get otherUnion (): string | null {
    return this.applicationState.otherUnion
  }

  set otherUnion (otherUnion: string | null) {
    this.setOtherUnion(otherUnion)
  }

  public async isValid () {
    const isValid = await this.$refs.observer.validate()
    return isValid
  }
}
