
/* Decorators */
import { Component, Vue, Prop } from 'vue-property-decorator'
import { State } from 'vuex-class'

/* Types */
import { WorkStatus } from '../../../types'
import { ApplicationState } from '../../../store/application/types'

/* Components */
import { Icon } from '@fagforbundet/components'

@Component({
  components: {
    Icon
  }
})
export default class WorkStatusPicker extends Vue {
  @State('application') applicationState!: ApplicationState
  @Prop({ type: Object, required: true }) option!: WorkStatus
  @Prop({ type: Number, required: true }) tabindex!: number

  get iconName (): string {
    const icon = this.option.id === 'pupil' ? 'student' : this.option.id
    return `pages/join/workStatus/${icon}`
  }

  get isSelected (): boolean {
    return this.applicationState.enlisteeType === this.option.id
  }

  get isPristine (): boolean {
    return this.applicationState.enlisteeType === ''
  }

  get itemClasses () {
    return {
      'is-selected': this.isSelected,
      'is-pristine': this.isPristine
    }
  }

  select (): void {
    this.$emit('select', this.option.id)
  }

  handleKeyDown (event: KeyboardEvent): void {
    if (event.keyCode === 13) {
      this.select()
    }
  }
}
