
import { Component, Vue } from 'vue-property-decorator'
import { FormRow, FormCheckbox, Icon } from '@fagforbundet/components'
import { Mutation, State } from 'vuex-class'
import { ApplicationState, MutationTypes } from '@/store/application/types'

@Component({
  components: {
    Icon,
    FormRow,
    FormCheckbox
  }
})
export default class WelcomeGiftForm extends Vue {
  @State('application') applicationState!: ApplicationState
  @Mutation(MutationTypes.SET_RESERVE_AGAINST_WELCOME_GIFT, {
    namespace: 'application'
  })
  setReservedWelcomeGift!: (value: boolean) => void

  get rejectsGift (): boolean {
    return !!this.applicationState.reservedWelcomeGift
  }

  set rejectsGift (value: boolean) {
    this.setReservedWelcomeGift(value)
  }
}
