/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosPromise } from 'axios'
import { Globals } from '../Globals'
import { Country } from '@/store/content/types'

export default {
  getCityFromPostalCode (postalCode: string): AxiosPromise<any> {
    return axios.get(
      `${Globals.ID_API_URL}/v1/postal-codes/lookup/country/no/postal-code/${postalCode}`
    )
  },

  getCountries (locale: 'nb-NO' | 'en-us' = 'nb-NO'): AxiosPromise<{ countries: Country[] }> {
    return axios({
      method: 'GET',
      baseURL: Globals.ID_API_URL,
      url: `/v1/countries/${locale}`
    })
  },

  validateSsn (ssn: string): AxiosPromise<any> {
    return axios.get(`${Globals.ID_API_URL}/v1/validate/ssn/${ssn}`)
  },

  validateEmail (email: string): AxiosPromise<any> {
    return axios.get(`${Globals.ID_API_URL}/v1/validate/email/${email}`)
  },

  validatePhone (phone: string): AxiosPromise<any> {
    return axios.get(`${Globals.ID_API_URL}/v1/validate/phone/${phone}`)
  },

  tokenAuthorizationCodePublicGrant (): AxiosPromise<{
    access_token: string;
    token_type: string;
    expires_in: number;
  }> {
    return axios({
      method: 'POST',
      baseURL: Globals.ID_API_URL,
      url: '/v1/oauth/token',
      data: {
        client_id: Globals.CLIENT_ID,
        grant_type:
          'https://fagforbundet.no/v1/oauth/grant-type/public_client_credentials'
      }
    })
  }
}
