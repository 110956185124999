import { ActionTree } from 'vuex'
import { ContentState } from './types'
import { RootState } from '../types'
import MembershipApiService from '../../services/MembershipApiService'
import IdApiService from '@/services/IdApiService'

export const actions: ActionTree<ContentState, RootState> = {
  async fetchPublicGrantToken ({ commit }) {
    return IdApiService.tokenAuthorizationCodePublicGrant().then((response) => {
      commit('publicGrantTokenLoaded', response.data.access_token)
    })
  },
  async fetchLoUnions ({ commit, state, dispatch }): Promise<void> {
    if (!state.publicGrantToken) {
      await dispatch('fetchPublicGrantToken')
    }
    return MembershipApiService.getOrganizationalUnits(state.publicGrantToken ?? '', {
      types: 'ORGANIZATIONAL_UNIT'
    })
      .then((resp) => {
        commit('loUnionsLoaded', resp.data.organizationalUnits ?? [])
      })
      .catch(() => {
        commit('loUnionsError')
      })
  },
  async fetchProfessions ({ commit }): Promise<void> {
    return MembershipApiService.getProfessions()
      .then((resp) => {
        commit('professionsLoaded', resp.data.professions ?? [])
      })
      .catch(() => {
        commit('professionsError')
      })
  },
  async fetchCounties ({ commit, state, dispatch }): Promise<void> {
    if (!state.publicGrantToken) {
      await dispatch('fetchPublicGrantToken')
    }

    return MembershipApiService.getOrganizationalUnits(
      state.publicGrantToken ?? '',
      {
        filterName: 'counties'
      }
    )
      .then((response) => {
        commit('countiesLoaded', response.data.organizationalUnits ?? [])
      })
      .catch(() => {
        commit('countiesError')
      })
  },
  async fetchCountries ({ commit }, locale?: 'nb-NO' | 'en-us'): Promise<void> {
    return IdApiService.getCountries(locale)
      .then((response) => {
        commit('countriesLoaded', response.data.countries ?? [])
      })
      .catch(() => {
        commit('countriesError')
      })
  }
}
