import { Module } from 'vuex'
import { getters } from './getters'
import { actions } from './actions'
import { mutations } from './mutations'
import { ApplicationState } from './types'
import { RootState } from '../types'

export const state: ApplicationState = {
  currentStep: 1,
  isPostAndFinanceUnionMember: false,
  selectedPostAndFinanceUnionCompany: false,
  referrerIdentifier: null,
  referrerName: null,
  foundPersonFromSearch: null,
  firstName: '',
  lastName: '',
  address: '',
  postalCode: '',
  postalArea: '',
  countryCode: null,
  phone: { countryCode: '+47', phoneNumber: '' },
  emailAddress: '',
  nin: '',
  enlisteeType: '',

  countyOrganizationalUnitUuid: '',
  forwardOtherUnion: true,
  memberOfOtherUnion: null,
  otherUnion: null,

  // Student
  studyArea: '',
  studySubject: '',
  studyPlace: '',
  studentFinishedWhen: '',

  // Apprentice
  apprenticeSubject: '',
  apprenticeStudyArea: '',
  apprenticeEmployer: '',
  apprenticeFinishedWhen: '',
  apprenticeIncludeInsurance: false,

  // Pupil
  pupilStudyDirection: '',
  pupilSchool: '',
  pupilStudyArea: '',
  pupilFinishedWhen: '',
  pupilIncludeInsurance: false,

  // Workplace
  hasTwoEmployers: false,
  postkomEmployer: null,
  postkomOtherEmployer: null,
  employer: null,
  employeenumber: null,
  employerOtherEmployer: null,
  workplace: '',
  professionUuid: null,
  otherProfession: '',
  position: '',
  workplacePercentage: 100,

  employer2: null,
  employer2OtherEmployer: null,
  workplace2: '',
  professionUuid2: null,
  otherProfession2: '',
  position2: '',
  workplacePercentage2: 0,

  wasEnlisted: false,
  enlisterMemberNr: '',
  enlisterFirstname: '',
  enlisterPhone: { countryCode: '+47', phoneNumber: '' },
  enlisterLastname: '',
  enlisterAddress: '',
  enlisterZipCode: '',
  enlisterArea: '',
  enlisterEmail: '',

  note: '',
  reservedTravelInsurance: false,
  reservedWelcomeGift: false,

  showAddressForm: false,
  stepOneValid: false,
  stepTwoValid: false,
  stepThreeValid: false,
  stepFourValid: false
}

const namespaced = true

export const application: Module<ApplicationState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
