
/* Decorators */
import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'

/* Components */
import { Icon } from '@fagforbundet/components'

/* Types */
import { ApplicationState } from '../../store/application/types'

@Component({
  components: {
    Icon
  }
})
export default class StepFive extends Vue {
  @State('application') applicationState!: ApplicationState

  get firstname (): string {
    return this.applicationState.firstName ?? ''
  }
}
