const messages = {
  no: {
    global: {
      next: 'Neste steg',
      back: 'Forrige steg',
      digits: 'siffer',
      workplace: 'Arbeidssted',
      yes: 'Ja',
      no: 'Nei',
      fall: 'høst',
      spring: 'vår',
      fieldrequired: 'Dette feltet er påkrevet',
      optional: 'Dette feltet er valgfritt',
      complete: 'Fullfør',
      validationfailed: 'Validering av innmelding feilet',
      searchfor: 'Søk etter…',
      phonenumber: 'Ditt nummer'
    },
    enlisted: {
      title: 'Ble du vervet?',
      iwasenlistedbysomeoneelse: 'Jeg ble vervet av noen andre',
      iwasenlisted: 'Ja, jeg ble vervet.',
      iwasenlistedby: 'Ja, jeg ble vervet av',
      havephonenumber: 'Hvis du har telefonnummer eller e-postadresse til den som vervet deg ber vi deg legge det inn nedenfor.',
      enlisterphonenumber: 'Ververs telefonnummer',
      enlistersemaillabel: 'Ververs e-post'
    },
    gift: {
      title: 'Fagforbundetsekken',
      info: 'Som nytt medlem i Fagforbundet mottar du en praktisk ryggsekk - helt gratis.',
      checkbox: 'Nei, jeg ønsker ikke å motta Fagforbundetsekken gratis.'
    },
    otherunion: {
      forward: 'Dersom du ikke tilhører vårt organisasjonsområde, ønsker du at vi videresender dine opplysninger til riktig LO-forbund?',
      directanswer: 'direkte svar til deg',
      memberotherunion: 'Er du per i dag medlem i et annet LO-forbund?',
      description: 'Fagforbundet trenger denne informasjonen for å videreføre LO-medlemskapet ditt med riktig innmeldingsdato. Husk å melde deg ut av forbundet du er medlem av per i dag når du blir tatt inn som medlem i Fagforbundet.',
      selectlabel: 'Hvilket forbund er du medlem av per i dag?'
    },
    situation: {
      employed: 'I jobb',
      student: 'Student',
      apprentice: 'Lærling',
      pupil: 'Elev'
    },
    county: {
      label: 'Velg fylke hvor du jobber',
      studentlabel: 'Velg fylke hvor du studere',
      placeholder: 'Vennligst velg fylke'
    },
    student: {
      studysubjectlabel: 'Fag/Studielinje',
      schoollabel: 'Skole/universitet',
      finishedlabel: 'Studiet varer t.o.m.',
      finishedplaceholder: 'Velg år / semester',
      pricelineone: 'Kontingent for studenter: kr. 250,- pr. halvår',
      pricelinetwo: '(Inkludert LOfavør Innboforsikring og Fagforbundets stønadskasse)',
      pricelinethree: '<a href="https://www.fagforbundet.no/medlemsfordeler/priser/" target="_blank">Les mer</a>'
    },
    apprentice: {
      until: 'Læretiden varer t.o.m.',
      pricelineone: 'Lærlingmedlemskap i Fagforbundet Post og finans',
      pricelinetwo: 'Kontingent pris kr. 219,- i mnd',
      pricelinethree: 'Å være medlem i Fagforbundet er gratis for lærlinger. Vi tilbyr en billig innboforsikring til 250,- per halvår. Dette er valgfritt.'
    },
    pupil: {
      studydirectionlabel: 'Studieretning',
      schoollabel: 'Skole',
      price: 'Å være medlem i Fagforbundet er gratis for elever. Vi tilbyr en billig innboforsikring til 250,- per halvår. Dette er valgfritt.',
      wantinsurance: 'Ja, jeg ønsker LOfavør innboforsikring.'
    },
    employee: {
      employeenumber: 'Ansattnummer',
      workplacehelptext: 'Arbeidssted, avdeling eller enhet.',
      otherprofession: 'Annet yrke',
      positionotherthanprofession: 'Oppgis dersom stilling avviker fra yrke.',
      percentagehelptext: 'Oppgis som heltall.',
      helptext: 'Oppgi arbeidsgiver her hvis du ikke fant din i søket over.',
      pricelineone: 'For Fagforbundet Post og finans medlemmer gjelder 1,20 % av bruttolønn',
      pricelinetwo: '<a href="https://www.fagforbundet.no/yrke/post-og-finans/" target="_blank">Les mer om Fagforbundet Post og Finans</a>',
      pricelinethree: '<a href="https://www.fagforbundet.no/globalassets/globale-filer/forsikringsordninger-for-fagforbundet-post-og-finans-2021.pdf" target="_blank">Les mer om forsikringsordningene</a>',
      pricelinefour: 'Yrkesaktiv kontingent: <a href="https://www.fagforbundet.no/medlemsfordeler/priser/" target="_blank">1,20% av bruttolønn</a>',
      pricelinefive: 'Noen fagforeninger kan ha <a href="https://www.fagforbundet.no/medlemsfordeler/priser/lokal-kontingent/" target="_blank">en forhøyet kontingent</a>',
      pricelinesix: '<a href="https://www.fagforbundet.no/medlemsfordeler/forsikringer/" target="_blank">Les mer om forsikringsordningene</a>'
    },
    employer: {
      description: 'Arbeidsgiver er bedriften eller kommunen du er ansatt i og mottar lønn fra. Hvis du er usikker, kan du sjekke lønnslippen din.',
      mainemployer: 'Hovedarbeidsgiver',
      othermailemployer: 'Annen hovedarbeidsgiver',
      otheremployer: 'Annen arbeidsgiver',
      employer: 'Arbeidsgiver',
      helptext: 'Oppgi hovedarbeidsgiver her hvis du ikke fant din i søket over.',
      position: 'Stilling',
      profession: 'Yrke',
      percentage: 'Stillingsprosent',
      moreemployers: 'Har du flere arbeidsgivere?<br />Legg til ny arbeidsgiver',
      removeemployer: 'Fjern arbeidsgiver 2'
    },
    progress: {
      personalinformation: 'Personalia',
      membership: 'Medlemskap',
      otherinformation: 'Annet'
    },
    identity: {
      phonenumber: 'Telefonnummer',
      forename: 'Fornavn',
      forenameplaceholder: 'Fornavn + mellomnavn',
      surname: 'Etternavn',
      address: 'Adresse',
      addresslabel: 'Gatenavn og -nummer',
      email: 'E-post',
      enlistersemail: 'Ververs e-post',
      emailplaceholder: 'E-postadresse',
      zipcodelabel: 'Postnr.',
      citylabel: 'Sted',
      personalsecuritynumber: 'Fødselsnummer',
      explanationtitle: 'Du som vil bli nytt medlem må fylle ut telefonnummer og fødselsnummer før selve innmeldingen kan fortsette. Vi trenger dette for å:',
      explanationpointone: 'For å bekrefte din identiet.',
      explanationpointtwo: 'For å sikre at du får de forsikringene du har krav på i gjennom medlemskapet.',
      explanationpointthree: 'For å identifisere innbetalingen av kontingenten og sikre at du får skattefradrag for den',
      explanationparagraphone: 'Hvis du har blitt vervet av noen, kan du opplyse om det i steg 3 av innmeldingen.',
      explanationparagraphtwo: 'Når du fortsetter innmelding kan vi forsøke å hente din kontaktinformasjon fra Bisnode. Trykk på «Hent mine opplysninger». Hvis du vil skrive opplysningene selv kan du trykke på  «Fyll ut skjema manuelt».',
      explanationparagraphthree: 'Du kan lese hvordan vi ivaretar ditt personvern i <a href="https://www.fagforbundet.no/personvern/" target="_blank">vår personvernerklæring</a>.<br />Dersom du har spørsmål/problemer vedrørende innmeldingsskjema ta <a href="https://www.fagforbundet.no/kontakt-oss/" target="_blank">kontakt med oss</a>.',
      fetchinformation: 'Hent mine opplysninger',
      filloutmanually: 'Fyll ut skjema manuelt',
      emailreason: 'Denne e-postadressen benyttes til å sende deg velkomstbrev og ytterligere informasjon som angår medlemskapet ditt.'
    },
    step1: {
      title: 'Bli en av oss!',
      foundinformation: 'Vi har funnet noen opplysninger for deg. Se over at dette er riktig før du går videre, og gjør nødvendige endringer.',
      foundnoinformation: 'Vi klarte ikke å hente opplysninger om deg. Vennligst fyll ut skjemaet manuelt.'
    },
    step2: {
      title: 'Velg rollen som passer deg'
    },
    step3: {
      title: 'Annet',
      specialremarks: 'Har du noen spesielle merknader, fyll dem ut her:',
      reserve: 'Ønsker du å reservere deg mot vår reiseforsikring?',
      errorenlisterphone: 'Kan ikke være samme telefonnummer som den som blir vervet',
      errorenlisteremail: 'Kan ikke være samme e-postadresse som den som blir vervet'
    },
    step4: {
      title: 'Innmelding mottatt.',
      intro: 'Takk for at du vil bli en av oss!<br />Nå må din innmelding behandles ferdig før du er helt i mål.',
      nextsteptitle: 'Hva skjer nå?',
      stepone: '<strong>Innmelding behandles</strong><p>Ditt regionskontor mottar din innmelding og behandler denne fortløpende. Du vil motta en SMS når innmeldingen er effektuert.</p>',
      steptwo: '<strong>Velkomstpakke</strong><p>Deretter vil du motta din velkomstpakke med informasjon og medlemsbevis.</p>',
      moreinformationone: 'Dersom du jobber i en virksomhet som Fagforbundet ikke organiserer, vil du få tilbakemelding med henvisning til et av våre søsterforbund i LO.',
      moreinformationtwo: 'For mer informasjon, besøk <a href="https://fagforbundet.no" target="_blank">fagforbundet.no</a>.'
    }
  },
  en: {
    global: {
      next: 'Next step',
      back: 'Go back',
      digits: 'digits',
      workplace: 'Workplace',
      yes: 'Yes',
      no: 'No',
      fall: 'fall',
      spring: 'spring',
      fieldrequired: 'This field is required',
      optional: 'This field is optional',
      complete: 'Complete registration',
      validationfailed: 'Validation of registration failed',
      searchfor: 'Search',
      phonenumber: 'Your phoneumber'
    },
    enlisted: {
      title: 'Were you enlisted?',
      iwasenlistedbysomeoneelse: 'I was enlisted by someone else',
      iwasenlisted: 'Yes, I was enlisted',
      iwasenlistedby: 'Yes, I was enlisted by',
      havephonenumber: 'If you have a phonenumber or e-mail address to the person that enlisted you, please enter it below.',
      enlisterphonenumber: 'Enlisters phonenumber',
      enlistersemaillabel: 'Enlisters e-mail'
    },
    gift: {
      title: 'Fagforbundet backpack',
      info: 'As a new member, you will recieve a practial backpack - free of charge',
      checkbox: 'No, i do not wish to recieve the Fagforbundet backpack for free'
    },
    otherunion: {
      forward: 'Would you like us to forward the application to the right LO-union?',
      directanswer: 'answer you directly',
      memberotherunion: 'Do you have a membership in a LO-union today?',
      description: 'Fagforbundet need this information to continue your LO-membership with the correct registration date. Remember to cancel the membership with the union your currently a member in when you\'re included as a member in Fagforbundet.',
      selectlabel: 'What other union are you a member in today?'
    },
    situation: {
      employed: 'In-job',
      student: 'Student',
      apprentice: 'Apprentice',
      pupil: 'Pupil'
    },
    county: {
      label: 'Please pick the county your workplace is in',
      studentlabel: 'Please pick the county where you are studying',
      placeholder: 'Select county'
    },
    student: {
      studysubjectlabel: 'Subject/line of study',
      schoollabel: 'School/university',
      finishedlabel: 'Finish-time',
      finishedplaceholder: 'Pick year / semester',
      pricelineone: 'Subscription: NOK 250,- per semester for students.',
      pricelinetwo: '(Includes insurances: LOfavør innboforsikring and Fagforbundets stønadskasse)',
      pricelinethree: '<a href="https://www.fagforbundet.no/medlemsfordeler/priser/" target="_blank">Read more</a>'
    },
    apprentice: {
      until: 'Finish-time',
      pricelineone: 'Apprentice membership in Fagforbundet Mail and Finance',
      pricelinetwo: 'Price: NOK 219,- per month',
      pricelinethree: 'For apprentices, a membership in Fagforbundet is free. We can offer you an insurance (LOfavør innboforsikring) if you need. Price: NOK 250,- per semester.'
    },
    pupil: {
      studydirectionlabel: 'Study subject',
      schoollabel: 'School',
      price: 'For pupils, a membership in Fagforbundet is free. We can offer you an insurance (LOfavør innboforsikring) if you need. Price: NOK 250,- per semester.',
      wantinsurance: 'Yes,I want LOFavør innboforsikring.'
    },
    employee: {
      employeenumber: 'Employee number',
      workplacehelptext: 'Workplace, department or unit',
      otherprofession: 'Other profession',
      positionotherthanprofession: 'Tell us your position if it deviates from your profession.',
      percentagehelptext: 'Enter integer',
      helptext: 'Enter employer if not found in the search above.',
      pricelineone: 'For Fagforbundet Mail and Finance members the subscription is 1,20 % of gross salary',
      pricelinetwo: '<a href="https://www.fagforbundet.no/yrke/post-og-finans/" target="_blank">Read more about Fagforbundet Mail and Finance</a>',
      pricelinethree: '<a href="https://www.fagforbundet.no/globalassets/globale-filer/forsikringsordninger-for-fagforbundet-post-og-finans-2021.pdf" target="_blank">Les mer om forsikringsordningene</a>',
      pricelinefour: 'Subscription: <a href="https://www.fagforbundet.no/medlemsfordeler/priser/" target="_blank">1,20% of gross salary.</a>',
      pricelinefive: 'Some local trade unions may have <a href="https://www.fagforbundet.no/medlemsfordeler/priser/lokal-kontingent/" target="_blank">elevated subscription</a>',
      pricelinesix: '<a href="https://www.fagforbundet.no/medlemsfordeler/forsikringer/" target="_blank">Read more about membership insurances</a>'
    },
    employer: {
      description: 'The employer is the exploit or municipality you are employed by and get your paycheck from. Are you not sure who it is? Check your paycheck.',
      mainemployer: 'Main employer',
      othermailemployer: 'Other main employer',
      otheremployer: 'Other employer',
      employer: 'Employer',
      helptext: 'Enter your main employer here if you didn\'t find yours om the search.',
      position: 'Position',
      profession: 'Profession',
      percentage: 'Position percentage',
      moreemployers: 'Do you have multiple employers?<br />Add new employer',
      removeemployer: 'I don\'t need employer 2'
    },
    progress: {
      personalinformation: 'Personal information',
      membership: 'Membership',
      otherinformation: 'Other information'
    },
    identity: {
      phonenumber: 'Phone number',
      forename: 'Forename',
      forenameplaceholder: 'Forename',
      surname: 'Surname',
      address: 'Address',
      addresslabel: 'Address',
      email: 'E-mail',
      enlistersemail: 'Enlisters e-mail',
      emailplaceholder: 'E-mail address',
      zipcodelabel: 'Zip code',
      citylabel: 'City',
      personalsecuritynumber: 'Personal security number',
      explanationtitle: 'We need your personal security number for several causes:',
      explanationpointone: 'To confirm your identity',
      explanationpointtwo: 'To ensure you get right insurances connected to your membership',
      explanationpointthree: 'To identify your subscription payment and tax deductions',
      explanationparagraphone: 'Continue enrollment by click "Pick my Information". We\'ll contact Bisnode for your personal information. Otherwise, click "Fill out form manually" to continue your enrollment.',
      explanationparagraphtwo: 'For privacy statement, follow <a href="https://www.fagforbundet.no/personvern/" target="_blank">this link</a>.',
      explanationparagraphthree: 'For questions of problems continue enrollment, please <a href="https://www.fagforbundet.no/kontakt-oss/" target="_blank">contact us</a>.',
      fetchinformation: 'Pick my Information',
      filloutmanually: 'Fill out form manually',
      emailreason: 'We will use this e-mail address to send information about your membership.'
    },
    step1: {
      title: 'Join us!',
      foundinformation: 'This is the information we found. Please read, control and insert information before next step.',
      foundnoinformation: 'We could not find any information. Please fill out the form manually.'
    },
    step2: {
      title: 'What\'s your situation?'
    },
    step3: {
      title: 'Other information',
      specialremarks: 'Do you want to tell us something? Please write here:',
      reserve: 'Do you wish to reserve against our travel insurance?',
      errorenlisterphone: "Can't be the same phone number as the enlisted",
      errorenlisteremail: "Can't be the same e-mail address as the enlisted"
    },
    step4: {
      title: 'Application for membership is recieved.',
      intro: 'Thank you for joining Fagforbundet!<br />Your application for membership will be dealt with.',
      nextsteptitle: 'What happens next?',
      stepone: '<p>Your application for membership will be dealt with at your regional office. When everything is ok, we send you an SMS.</p>',
      steptwo: '<p>A welcome gift will come to you, containing information and a membership certificate.</p>',
      moreinformationone: 'If we had to send your application for membership to another LO-union, you will be hearing from the, when it is dealt with.',
      moreinformationtwo: 'For more information: <a href="https://fagforbundet.no" target="_blank">fagforbundet.no</a>.'
    }
  }
}

export default messages
