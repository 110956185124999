
/* eslint-disable @typescript-eslint/camelcase */
/* Decorators */
import { Component, Vue } from 'vue-property-decorator'
import { Mutation, State } from 'vuex-class'

/* Components */
import {
  FormRow,
  FormTextField,
  FormTypeaheadSelect
} from '@fagforbundet/components'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

/* Types */
import {
  MutationTypes,
  ApplicationState,
  Employer
} from '../../../store/application/types'
import { FormTypeaheadOption } from '../../../types'

/* Globals */
import { postkomEmployers } from '../../../Globals'

@Component({
  components: {
    FormRow,
    FormTextField,
    FormTypeaheadSelect,
    ValidationProvider,
    ValidationObserver
  }
})
export default class PostkomEmployer extends Vue {
  @State('application') applicationState!: ApplicationState
  @Mutation(MutationTypes.SET_POSTKOM_EMPLOYER, { namespace: 'application' })
  setPostkomEmployer!: (employer: Employer | null) => void

  @Mutation(MutationTypes.SET_POSTKOM_OTHER_EMPLOYER, {
    namespace: 'application'
  })
  setPostkomOtherEmployer!: (employer: Employer | null) => void

  public $refs!: {
    observer: InstanceType<typeof ValidationObserver>;
  }

  private mounted (): void {
    console.log('PostkomEmployer::mounted()')
  }

  get postkomEmployersList (): FormTypeaheadOption[] {
    const employers: FormTypeaheadOption[] = [
      {
        id: this.$t('employer.otheremployer').toString(),
        value: { name: this.$t('employer.otheremployer').toString() },
        label: this.$t('employer.otheremployer').toString()
      }
    ]
    return employers.concat(
      postkomEmployers.map((employer) => {
        return {
          id: employer.organizationNumber ?? '',
          value: employer,
          label: employer.name
        }
      })
    )
  }

  get postkomEmployer (): Employer | null {
    return this.applicationState.postkomEmployer
  }

  set postkomEmployer (postkomEmployer: Employer | null) {
    this.setPostkomEmployer(postkomEmployer)
  }

  get postkomOtherEmployer (): Employer | null {
    return this.applicationState.postkomOtherEmployer
  }

  set postkomOtherEmployer (postkomOtherEmployer: Employer | null) {
    this.setPostkomOtherEmployer(postkomOtherEmployer)
  }

  public async isValid () {
    const isValid = await this.$refs.observer.validate()
    return isValid
  }
}
