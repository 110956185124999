
/* Decorators */
import { Component, Vue } from 'vue-property-decorator'
import { State, Mutation, Getter, Action } from 'vuex-class'

/* Components */
import {
  Alert,
  FormButton,
  FormRow,
  FormColumn,
  FormRadio,
  FormRadioOption,
  FormTextArea,
  Icon
} from '@fagforbundet/components'
import EnlisterForm from './components/EnlisterForm.vue'
import WelcomeGiftForm from './components/WelcomeGiftForm.vue'
import Progress from './components/Progress.vue'
import OtherUnion from './components/OtherUnion.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

/* Types */
import {
  ApplicationState,
  MutationTypes,
  ActionTypes,
  MembershipApplication
} from '../../store/application/types'
import { Violation } from '../../types'

/* Services */
import MembershipApiService from '../../services/MembershipApiService'
import { ContentState } from '@/store/content/types'

@Component({
  components: {
    Progress,
    OtherUnion,
    WelcomeGiftForm,
    EnlisterForm,
    Alert,
    FormTextArea,
    FormRadio,
    FormRadioOption,
    FormButton,
    FormRow,
    FormColumn,
    Icon,
    ValidationProvider,
    ValidationObserver
  }
})
export default class StepThree extends Vue {
  @State('application') applicationState!: ApplicationState
  @State('content') contentState!: ContentState
  @Getter('isPostAndFinanceUnionMember', { namespace: 'application' })
  isPostAndFinanceUnionMember!: boolean

  @Getter('hasSelectedPostAndFinanceUnionCompany', { namespace: 'application' })
  hasSelectedPostAndFinanceUnionCompany!: boolean

  @Mutation(MutationTypes.SET_RESERVE_AGAINST_TRAVEL_INSURANCE, {
    namespace: 'application'
  })
  setReserveAgainstTravelInsurance!: (
    reserveAgainstTravelInsurance: boolean
  ) => void

  @Mutation(MutationTypes.SET_EXTRA_INFO, { namespace: 'application' })
  setExtraInfo!: (extraInfo: string) => void

  @Mutation(MutationTypes.SET_CURRENT_STEP, { namespace: 'application' })
  setCurrentStep!: (currentStep: number) => void

  @Mutation(MutationTypes.SET_STEP_THREE_VALID, { namespace: 'application' })
  setStepThreeValid!: (stepOneValid: boolean) => void

  @Action(ActionTypes.CLEAR_STATE, { namespace: 'application' })
  clearState!: () => void

  @Getter('getFormData', { namespace: 'application' })
  getFormData!: MembershipApplication

  loading = false
  showErrorAlert = false
  errorMessages: string[] = []
  public $refs!: {
    otherUnion: OtherUnion;
    enlisterForm: EnlisterForm;
    observer: InstanceType<typeof ValidationObserver>;
  }

  async goToNextStep () {
    const selfValid = await this.isValid()
    if (!selfValid || this.loading) {
      return
    }

    this.loading = true
    this.showErrorAlert = false

    await MembershipApiService.storeEnlistment(
      this.contentState.publicGrantToken ?? '',
      this.getFormData
    )
      .then(() => {
        this.setCurrentStep(3)
        this.setStepThreeValid(true)
        this.$router.push({ path: '/step4' })
        this.clearState()
        this.loading = false
      })
      .catch((error) => {
        // const message = error && error.response && error.response.data && error.response.data.error
        const violations: Violation[] =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.violations
        this.errorMessages = []
        if (violations) {
          violations.forEach((violation: Violation) => {
            const field = this.getReadableName(violation.propertyPath)
            const message = this.getReadableMessage(violation.message)
            this.errorMessages.push(`${field}: ${message}`)
          })
          this.showErrorAlert = true
        }
        this.$rollbar.log('error', error.response.data)
        console.log('--------------------------------------------')
        console.log('Feilmelding:')
        console.log(error.response.data)
        console.log('--------------------------------------------')
        this.loading = false
      })
  }

  get reserveAgainstTravelInsurance (): string | null {
    if (this.applicationState.reservedTravelInsurance === null) {
      return null
    }
    return this.applicationState.reservedTravelInsurance ? '1' : '0'
  }

  set reserveAgainstTravelInsurance (
    reserveAgainstTravelInsurance: string | null
  ) {
    this.setReserveAgainstTravelInsurance(
      Boolean(reserveAgainstTravelInsurance === '1')
    )
  }

  private getReadableName (name: string) {
    switch (name) {
      case '[application][emailAddress]':
        return this.$t('identity.emailAddress')
      case '[application][referral][emailAddress]':
        return this.$t('enlisted.enlistersemaillabel')
      case '[application][referral][phoneNumber]':
        return this.$t('enlisted.enlisterphonenumber')
      default:
        return name
    }
  }

  private getReadableMessage (message: string) {
    switch (message) {
      case "This value can't equal phoneNumber":
        return this.$t('step3.errorenlisterphone')
      case "This value can't equal emailAddress":
        return this.$t('step3.errorenlisteremail')
      default:
        return message
    }
  }

  public async isValid () {
    const isValid = await this.$refs.observer.validate()
    const otherUnionIsValid = await this.$refs.otherUnion.isValid()
    const enlisterFormIsValid = await this.$refs.enlisterForm.isValid()
    return isValid && otherUnionIsValid && enlisterFormIsValid
  }

  goToPreviousStep (): void {
    this.$router.push({ path: '/step2' })
  }

  get extraInfo (): string {
    return this.applicationState.note
  }

  set extraInfo (extraInfo: string) {
    this.setExtraInfo(extraInfo)
  }
}
