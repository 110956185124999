import Vue from 'vue'
import Vuex, { StoreOptions } from 'vuex'
import { RootState } from './types'
import { application } from './application/index'
import { content } from './content/index'

Vue.use(Vuex)

const store: StoreOptions<RootState> = {
  state: {
    version: '1.0.0'
  },
  modules: {
    application,
    content
  }
}

export default new Vuex.Store<RootState>(store)
