export enum MutationTypes {
  SET_LANGUAGE = 'SET_LANGUAGE',
  SET_IS_POST_AND_FINANCE_UNION_MEMBER = 'SET_IS_POST_AND_FINANCE_UNION_MEMBER',
  SET_SELECTED_POST_AND_FINANCE_UNION_COMPANY = 'SET_SELECTED_POST_AND_FINANCE_UNION_COMPANY',
  SET_REFERRER_IDENTIFIER = 'SET_REFERRER_IDENTIFIER',
  SET_REFERER_NAME = 'SET_REFERER_NAME',
  SET_CURRENT_STEP = 'SET_CURRENT_STEP',
  SET_FOUND_PERSON_FROM_SEARCH = 'SET_FOUND_PERSON_FROM_SEARCH',

  SET_FIRSTNAME = 'SET_FIRSTNAME',
  SET_LASTNAME = 'SET_LASTNAME',
  SET_ADDRESS = 'SET_ADDRESS',
  SET_ZIPCODE = 'SET_ZIPCODE',
  SET_AREA = 'SET_AREA',
  SET_EMAIL = 'SET_EMAIL',
  SET_PHONE = 'SET_PHONENUMBER',
  SET_NATIONAL_ID_NUMBER = 'SET_NATIONAL_ID_NUMBER',
  SET_ENLISTEE_TYPE = 'SET_ENLISTEE_TYPE',
  SET_STUDY_SUBJECT = 'SET_STUDY_SUBJECT',
  SET_STUDY_AREA = 'SET_STUDY_AREA',
  SET_STUDY_PLACE = 'SET_STUDY_PLACE',
  SET_STUDY_FINISHED_WHEN = 'SET_STUDY_FINISHED_WHEN',
  SET_FORWARD_TO_OTHER_UNION = 'SET_FORWARD_TO_OTHER_UNION',
  SET_MEMBER_OTHER_UNION = 'SET_MEMBER_OTHER_UNION',
  SET_OTHER_UNION = 'SET_OTHER_UNION',
  SET_COUNTY_UUID = 'SET_COUNTY_UUID',

  // Apprentice
  SET_APPRENTICE_SUBJECT = 'SET_APPRENTICE_SUBJECT',
  SET_APPRENTICE_STUDY_AREA = 'SET_APPRENTICE_STUDY_AREA',
  SET_APPRENTICE_EMPLOYER = 'SET_APPRENTICE_EMPLOYER',
  SET_APPRENTICE_FINISHED_WHEN = 'SET_APPRENTICE_FINISHED_WHEN',
  SET_APPRENTICE_INCLUDE_INSURANCE = 'SET_APPRENTICE_INCLUDE_INSURANCE',

  // Pupil
  SET_PUPIL_STUDY_DIRECTION = 'SET_PUPIL_STUDY_DIRECTION',
  SET_PUPIL_SCHOOL = 'SET_PUPIL_SCHOOL',
  SET_PUPIL_STUDY_AREA = 'SET_PUPIL_STUDY_AREA',
  SET_PUPIL_FINISHED_WHEN = 'SET_PUPIL_FINISHED_WHEN',
  SET_PUPIL_INCLUDE_INSURANCE = 'SET_PUPIL_INCLUDE_INSURANCE',

  // Workplace
  SET_POSTKOM_EMPLOYER = 'SET_POSTKOM_EMPLOYER',
  SET_POSTKOM_OTHER_EMPLOYER = 'SET_POSTKOM_OTHER_EMPLOYER',
  SET_HAS_TWO_EPLOYERS = 'SET_HAS_TWO_EPLOYERS',
  SET_EMPLOYER = 'SET_EMPLOYER',
  SET_EMPLOYEE_NUMBER = 'SET_EMPLOYEE_NUMBER',
  SET_OTHER_EMPLOYER = 'SET_OTHER_EMPLOYER',
  SET_WORKPLACE = 'SET_WORKPLACE',
  SET_PROFESSION_UUID = 'SET_PROFESSION_UUID',
  SET_OTHER_PROFESSION = 'SET_OTHER_PROFESSION',
  SET_POSITION = 'SET_POSITION',
  SET_WORKPLACE_PERCENTAGE = 'SET_WORKPLACE_PERCENTAGE',

  SET_EMPLOYER_2 = 'SET_EMPLOYER_2',
  SET_OTHER_EMPLOYER_2 = 'SET_OTHER_EMPLOYER_2',
  SET_WORKPLACE_2 = 'SET_WORKPLACE_2',
  SET_PROFESSION_UUID_2 = 'SET_PROFESSION_UUID_2',
  SET_OTHER_PROFESSION_2 = 'SET_OTHER_PROFESSION_2',
  SET_POSITION_2 = 'SET_POSITION_2',
  SET_WORKPLACE_PERCENTAGE_2 = 'SET_WORKPLACE_PERCENTAGE_2',

  SET_WAS_ENLISTED = 'SET_WAS_ENLISTED',
  SET_ENLISTER_MEMBER_NR = 'SET_ENLISTER_MEMBER_NR',
  SET_ENLISTER_FIRSTNAME = 'SET_ENLISTER_FIRSTNAME',
  SET_ENLISTER_LASTNAME = 'SET_ENLISTER_LASTNAME',
  SET_ENLISTER_ADDRESS = 'SET_ENLISTER_ADDRESS',
  SET_ENLISTER_ZIPCODE = 'SET_ENLISTER_ZIPCODE',
  SET_ENLISTER_AREA = 'SET_ENLISTER_AREA',
  SET_ENLISTER_PHONE = 'SET_ENLISTER_PHONE',
  SET_ENLISTER_EMAIL = 'SET_ENLISTER_EMAIL',

  SET_EXTRA_INFO = 'SET_EXTRA_INFO',
  SET_RESERVE_AGAINST_TRAVEL_INSURANCE = 'SET_RESERVE_AGAINST_TRAVEL_INSURANCE',
  SET_RESERVE_AGAINST_WELCOME_GIFT = 'SET_RESERVE_AGAINST_WELCOME_GIFT',

  SET_SHOW_ADDRESS_FORM = 'SET_SHOW_ADDRESS_FORM',
  SET_STEP_ONE_VALID = 'SET_STEP_ONE_VALID',
  SET_STEP_TWO_VALID = 'SET_STEP_TWO_VALID',
  SET_STEP_THREE_VALID = 'SET_STEP_THREE_VALID',
  SET_STEP_FOUR_VALID = 'SET_STEP_FOUR_VALID',

  SET_PERSON = 'SET_PERSON'
}
