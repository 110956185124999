
import { Component, Vue } from 'vue-property-decorator'
import { AppDecorated } from '@fagforbundet/components'
import { Getter } from 'vuex-class'

@Component({
  components: {
    AppDecorated
  }
})
export default class App extends Vue {
  @Getter('stepFourValid', { namespace: 'application' }) stepFourValid!: boolean
  mounted () {
    console.log('App::mounted()')

    const scriptElementId = 'piwik-script'

    const removePiwik = (removeCookies = true) => {
      if (removeCookies) {
        // Get an array of all cookie names (the regex matches what we don't want)
        const cookieNames = document.cookie.split(/=[^;]*(?:;\s*|$)/)

        // Remove any that match the pattern
        for (let i = 0; i < cookieNames.length; i++) {
          if (/^_pk/.test(cookieNames[i])) {
            document.cookie = cookieNames[i] + '=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/'
          }
        }
      }

      const scriptElement = document.getElementById(scriptElementId)
      if (scriptElement) {
        document.head.removeChild(scriptElement)
      }
    }
    const addPiwik = () => {
      const scriptElementToInject = document.createElement('script')
      scriptElementToInject.setAttribute('id', scriptElementId)
      scriptElementToInject.innerHTML = `(function(window, document, dataLayerName, id) {
        window[dataLayerName]=window[dataLayerName]||[],window[dataLayerName].push({start:(new Date).getTime(),event:"stg.start"});var scripts=document.getElementsByTagName('script')[0],tags=document.createElement('script');
        function stgCreateCookie(a,b,c){var d="";if(c){var e=new Date;e.setTime(e.getTime()+24*c*60*60*1e3),d="; expires="+e.toUTCString();f="; SameSite=Strict"}document.cookie=a+"="+b+d+f+"; path=/"}
        var isStgDebug=(window.location.href.match("stg_debug")||document.cookie.match("stg_debug"))&&!window.location.href.match("stg_disable_debug");stgCreateCookie("stg_debug",isStgDebug?1:"",isStgDebug?14:-1);
        var qP=[];dataLayerName!=="dataLayer"&&qP.push("data_layer_name="+dataLayerName),isStgDebug&&qP.push("stg_debug");var qPString=qP.length>0?("?"+qP.join("&")):"";
        tags.async=!0,tags.src="https://fagforbundet.containers.piwik.pro/"+id+".js"+qPString,scripts.parentNode.insertBefore(tags,scripts);
        !function(a,n,i){a[n]=a[n]||{};for(var c=0;c<i.length;c++)!function(i){a[n][i]=a[n][i]||{},a[n][i].api=a[n][i].api||function(){var a=[].slice.call(arguments,0);"string"==typeof a[0]&&window[dataLayerName].push({event:n+"."+i+":"+a[0],parameters:[].slice.call(arguments,1)})}}(i[c])}(window,"ppms",["tm","cm"]);
        })(window, document, 'dataLayer', '0ed8b5c8-2386-40e4-b0f2-f726f5af7318');`
      document.head.appendChild(scriptElementToInject)
    }

    window.addEventListener(
      'CookiebotOnAccept',
      (event: any) => {
        if (!event.currentTarget.CookieConsent.consent.statistics) {
          removePiwik()
        } else {
          removePiwik(false)
          addPiwik()
        }
      }
    )

    this.$cookiebot.consentBanner({})
  }

  get decoration (): string {
    return this.stepFourValid ? 'confirmation' : 'office'
  }
}
