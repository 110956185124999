
/* Decorators */
import { Component, Vue } from 'vue-property-decorator'
import { State, Mutation } from 'vuex-class'

/* Components */
import {
  FormRow,
  FormColumn,
  FormTextField,
  FormPhoneNumberField,
  FormButton,
  Button as AppButton
} from '@fagforbundet/components'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

/* Types */
import {
  ApplicationState,
  MutationTypes,
  Person
} from '../../../store/application/types'
import { PhoneNumber } from '../../../types'

/* Services */
import ProxyApiService from '../../../services/ProxyApiService'

@Component({
  components: {
    FormRow,
    FormColumn,
    FormTextField,
    FormPhoneNumberField,
    FormButton,
    AppButton,
    ValidationProvider,
    ValidationObserver
  }
})
export default class Identity extends Vue {
  @State('application') applicationState!: ApplicationState
  @Mutation(MutationTypes.SET_NATIONAL_ID_NUMBER, { namespace: 'application' })
  setNationalIdNumber!: (nationalIdNumber: string) => void

  @Mutation(MutationTypes.SET_PHONE, { namespace: 'application' }) setPhone!: (
    phone: PhoneNumber
  ) => void

  @Mutation(MutationTypes.SET_SHOW_ADDRESS_FORM, { namespace: 'application' })
  setShowAddressForm!: (validated: boolean) => void

  @Mutation(MutationTypes.SET_PERSON, { namespace: 'application' })
  setPerson!: (person: Person) => void

  @Mutation(MutationTypes.SET_FOUND_PERSON_FROM_SEARCH, {
    namespace: 'application'
  })
  setFoundPersonFromSearch!: (result: boolean) => void

  showLoader = false
  public $refs!: {
    observer: InstanceType<typeof ValidationObserver>;
  }

  get nationalIdNumber (): string {
    return this.applicationState.nin ?? ''
  }

  set nationalIdNumber (nationalIdNumber: string) {
    this.setNationalIdNumber(nationalIdNumber)
  }

  get phone (): PhoneNumber {
    return this.applicationState.phone
  }

  set phone (phone: PhoneNumber) {
    this.setPhone(phone)
  }

  public async isValid (silent = false) {
    const isValid = await this.$refs.observer.validate({ silent })
    return isValid
  }

  async manualPersonDetails () {
    const valid = await this.isValid()
    if (!valid) {
      return
    }
    this.setShowAddressForm(true)
  }

  async fetchAddressFromApi () {
    const valid = await this.isValid()
    if (!valid) {
      return
    }
    if (this.applicationState.nin === '17042096563') {
      this.setFoundPersonFromSearch(true)
      this.setPerson({
        firstName: 'Bork',
        lastName: 'Berntsen',
        address: 'Testveien 1A',
        postalCode: '0155',
        postalArea: 'Oslo'
      })
      this.setShowAddressForm(true)
    } else {
      this.showLoader = true
      ProxyApiService.searchPerson(
        `${this.applicationState.phone.phoneNumber}`,
        this.applicationState.nin
      )
        // eslint-disable-next-line
        .then(
          (resp) => {
            if (resp.status === 200) {
              const payload = resp && resp.data && resp.data.person
              this.setFoundPersonFromSearch(true)
              this.setPerson(payload)
            } else {
              this.setFoundPersonFromSearch(false)
              // Other status code. Probably error?
            }
            this.setShowAddressForm(true)
            this.showLoader = false
          },
          () => {
            this.setFoundPersonFromSearch(false)
            // An error occured
            this.setShowAddressForm(true)
            this.showLoader = false
          }
        )
    }
  }
}
