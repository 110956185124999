
/* Decorators */
import { Component, Vue } from 'vue-property-decorator'
import { State, Mutation } from 'vuex-class'

/* Components */
import { Alert, FormButton, Icon } from '@fagforbundet/components'
import Identity from './components/Identity.vue'
import Address from './components/Address.vue'
import Name from './components/Name.vue'
import County from './components/County.vue'
import Email from './components/Email.vue'
import Progress from './components/Progress.vue'
import Language from './components/Language.vue'

/* Types */
import { ApplicationState, MutationTypes } from '../../store/application/types'

@Component({
  components: {
    Progress,
    Identity,
    Name,
    Address,
    County,
    Email,
    Alert,
    FormButton,
    Language,
    Icon
  }
})
export default class StepOne extends Vue {
  @State('application') applicationState!: ApplicationState
  @Mutation(MutationTypes.SET_CURRENT_STEP, { namespace: 'application' })
  setCurrentStep!: (currentStep: number) => void

  @Mutation(MutationTypes.SET_STEP_ONE_VALID, { namespace: 'application' })
  setStepOneValid!: (stepOneValid: boolean) => void

  public $refs!: {
    identity: Identity;
    name: Name;
    address: Address;
    email: Email;
  }

  get showAddressForm () {
    return this.applicationState.showAddressForm
  }

  async goToNextStep () {
    const nameIsValid = await this.$refs.name.isValid()
    const addressIsValid = await this.$refs.address.isValid()
    const emailIsValid = await this.$refs.email.isValid()
    if (nameIsValid && addressIsValid && emailIsValid) {
      this.setCurrentStep(1)
      this.setStepOneValid(true)
      this.$router.push({ path: '/step2' })
    }
  }
}
